import { useSelector } from "react-redux"
import Buildings from "./buildings"
import ManagerDashboard from "./managerDashboard"


const Dashboard = () => {
  const user = useSelector((state: any) => state.app.user)

  console.log(user)


  return (
    <div className="container content-center mx-auto">
      {(user && user.trixtaMeta && user.trixtaMeta.roles) &&
        <>
          {user.trixtaMeta.roles.includes("property_manager") ?
            <ManagerDashboard /> : <Buildings />
          }
        </>
      }
    </div>
  )
}

export default Dashboard
