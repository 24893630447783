import { useRef } from "react";
import usePropertiesHook from "../../hooks/useProperties";
import { FormField } from "./formFieldStyles";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { UploadContent } from "../content/uploadContent";

export const EditDetailsCard = ({
  setEdit,
  propertyObj,
  setPropertyObj,
  template,
  propertyType,
  mainImg,
  setMainImg,
  onSubmit
}: any) => {
  const { addContent, pushThumbnail } = usePropertiesHook();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // unitize image add/upload (repeated in 3 locations)
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imageData = e.target.result;
        setMainImg([[file.name, imageData]]);
        setPropertyObj({ ...propertyObj, new_template: false });
        if (propertyType == "building") {
          UploadContent(
            ["thumbnail", imageData],
            "property",
            propertyObj.property_id,
            addContent
          );
          pushThumbnail({
            image: imageData,
            property_id: propertyObj.property_id
          });
        } else {
          UploadContent(
            ["thumbnail", imageData],
            "unit",
            propertyObj.property_id,
            addContent,
            propertyObj.unit_id
          );
          pushThumbnail({
            image: imageData,
            property_id: propertyObj.property_id,
            unit_id: propertyObj.unit_id
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const addToNestedState = (objName: string, name: string, value: string) => {
    setPropertyObj({
      ...propertyObj,
      [objName]: {
        ...propertyObj[objName],
        [name]: value
      }
    });
  };

  const onChangeEdit = (e: any) => {
    const { name, value } = e.target;
    if (["tenantName", "tenantEmail", "startDate", "endDate"].includes(name)) {
      addToNestedState("tenant", name, value);
    } else {
      console.log(name);
      console.log(value);
      setPropertyObj({ ...propertyObj, [name]: value });
    }
  };

  return (
    <form
      className="pt-4 pl-14 flex flex-col"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
        setEdit(false);
      }}
    >
      <div className="flex pb-6 pt-4 w-[25%]">
        <div className="edit-fields-titles pr-6 pt-1">Name</div>
        <div className="w-[100%]">
          <FormField
            name="name"
            valid={true}
            value={propertyObj["name"]}
            onChange={onChangeEdit}
          />
        </div>
      </div>

      <div className="flex">
        {mainImg === "empty" || !mainImg ? (
          <div
            className="w-60 h-60 rounded-xl bg-hover-grey flex items-center justify-center"
            onClick={handleIconClick}
          >
            <PlusCircleIcon className="text-slate-400 w-1/6 h-1/6" />
          </div>
        ) : (
          <img
            src={mainImg[0][1]}
            className="object-cover w-60 h-60 rounded-xl bg-hover-grey flex items-center justify-center"
            onClick={handleIconClick}
          />
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div className="pl-16 flex-grow">
          {Object.keys(template).map((key: string) => (
            <label className="pb-4 w-[100%] flex" key={key}>
              <div className="edit-fields-titles pt-1 w-[16%]">
                {template[key]["title"]}
              </div>

              <div className="w-[60%]">
                {key === "type" ? (
                  <select
                    name="type"
                    required
                    value={propertyObj[key]}
                    className="edit-fields border-gray-200"
                    onChange={onChangeEdit}
                  >
                    <option value=""></option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                ) : (
                  <FormField
                    name={key}
                    valid={true}
                    value={propertyObj[key]}
                    onChange={onChangeEdit}
                  />
                )}
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className="flex justify-end pt-4 mr-4">
        <button
          type="submit"
          className="bg-pass-blue mr-4 w-28 rounded-full h-8 text-sm text-clarity"
        >
          SAVE
        </button>
        <button
          className="bg-delete-red w-28 rounded-full h-8 text-sm text-clarity"
          onClick={() => setEdit(false)}
        >
          CANCEL
        </button>
      </div>
    </form>
  );
};
