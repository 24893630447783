import { configureStore } from "@reduxjs/toolkit";
import { createPhoenixChannelMiddleware } from "@trixtateam/phoenix-to-redux";
import createSagaMiddleware from "redux-saga";
import { setupTrixtaSaga } from "@trixtateam/trixta-js-core";
import createReducer from "../reducers/index";
import rootSaga from "../sagaFiles/rootSaga";

export default function configureTrixtaStore() {
  const reduxSagaMonitorOptions = {};
  // Makes redux connected to phoenix channels
  const phoenixChannelMiddleWare = createPhoenixChannelMiddleware();
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  // Create the store with saga middleware
  const middlewares = [sagaMiddleware, phoenixChannelMiddleWare];

  const enhancers: any = [];

  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        thunk: false,
        immutableCheck: {
          ignore: ["socket", "channel", "trixta", "phoenix", "router"]
        },
        serializableCheck: false
      }),
      ...middlewares
    ],
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== "production" ||
      // @ts-ignore
      process.env.PUBLIC_URL.length > 0,
    enhancers
  });

  sagaMiddleware.run(setupTrixtaSaga);
  sagaMiddleware.run(rootSaga);

  return store;
}
