export const propertyListTypes =  [
  "Residential",
  "Commercial",
  "Commercial",
  "Commercial",
  "Residential",
  "Commercial"
]

export const propertyListUnitNames = [
  "Offices",
  "Suits",
  "Rooms",
  "Blocks",
  "Flats",
  "bleh",

]

export const propertyListUnitTypes =  [
  [4, 2, 8, 12, 11, 12],
  [5, 6, 2, 1],
  [2, 23, 4, 5],
  [1, 1, 12, 8],
  [4, 1, 3, 2],
  [12, 3, 11, 3]
]