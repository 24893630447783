import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { profileTemplate } from "./profileTemplates";
import { useRef } from "react";

export const EditView = ({
  setEdit,
  profileData,
  onSubmit,
  onChange,
  updateContacts,
  setTempPfp,
  profilePicture
}: any) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imageData = e.target.result;
        setTempPfp(imageData);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex mr-14">
      {profilePicture === "" || !profilePicture ? (
        <div
          className="w-60 h-60 rounded-xl bg-hover-grey flex items-center justify-center"
          onClick={handleIconClick}
        >
          <PlusCircleIcon className="text-slate-400 w-1/6 h-1/6" />
        </div>
      ) : (
        <img
          src={profilePicture}
          className="object-cover w-60 h-60 rounded-xl bg-hover-grey flex items-center justify-center"
          onClick={handleIconClick}
        />
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div className="w-[70%] px-14 text-sm text-edit-grey mr-8">
        {Object.keys(profileTemplate).map((key: any) => {
          return (
            <div key={key} className="flex h-8 ">
              <div className="w-[30%] pt-1 font-semibold">
                <p>{profileTemplate[key].title}</p>
              </div>
              <input
                value={profileData[key] && profileData[key]}
                onChange={onChange}
                name={key}
                className="
                  mt-1 ml-8
                  block 
                  w-full 
                  rounded-full 
                  border-solid 
                  border-[1.5px] 
                  border-gray-200 
                  outline-none 
                  bg-white 
                  ring-0 
                  px-4"
              ></input>
            </div>
          );
        })}

        <div className="flex h-8 mt-2">
          <div className="w-[40%] pt-1 font-semibold">
            <p>Contact Numbers</p>
          </div>
        </div>

        {profileData.contactNumbers &&
          profileData.contactNumbers.map((numbers: any, index: number) => {
            return (
              <div key={index} className="flex mt-3">
                <div
                  className="
                  w-full"
                >
                  <p className="text-center pb-1">Name</p>
                  <input
                    value={numbers.name}
                    onChange={e => {
                      updateContacts("edit", index, e.target.value, "name");
                    }}
                    className="
                    h-7 w-full
                    mr-1
                    block 
                    rounded-full 
                    border-solid 
                    border-[1.5px] 
                    border-gray-200 
                    outline-none 
                    bg-white 
                    ring-0 
                    px-4"
                  ></input>
                </div>

                <div
                  className="
                  w-full ml-1"
                >
                  <p className="text-center pb-1">Number</p>
                  <input
                    value={numbers.number}
                    onChange={e => {
                      updateContacts("edit", index, e.target.value, "number");
                    }}
                    className="
                    ml-1 w-full
                    h-7
                    block 
                    rounded-full 
                    border-solid 
                    border-[1.5px] 
                    border-gray-200 
                    outline-none 
                    bg-white 
                    ring-0 
                    px-4"
                  ></input>
                </div>
                <button
                  onClick={() => updateContacts("remove", index)}
                  className="font-bold text-delete-red pl-2 flex align-top"
                >
                  <p>X</p>
                </button>
              </div>
            );
          })}

        <div className=" flex justify-center pt-4 w-full">
          <PlusCircleIcon
            onClick={() => updateContacts("add")}
            className="cursor-pointer text-slate-400 w-10 h-10"
          />
        </div>
        <div className="flex flex-grow justify-between pt-4">
          <button
            onClick={() => {
              onSubmit();
              setEdit(false);
            }}
            type="submit"
            className="bg-pass-blue w-28 rounded-full h-8 text-sm text-clarity"
          >
            SAVE
          </button>

          <button
            onClick={() => {
              updateContacts("reset");
              setEdit(false);
            }}
            type="submit"
            className="bg-delete-red w-28 rounded-full h-8 text-sm text-clarity"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  );
};
