import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import usePropertiesHook from "../hooks/useProperties";
import { submitFormTemplate } from "../components/formTemplates/buildingTemplates";
import { Breadcrumbs } from "../components/breadcrumbs";
import { loadThumbnail } from "../components/content/loadThumbnail";

import { PageTitle } from "../components/typography";
import { RemoveTemplateProperties } from "../components/propertyList/removeTemplateBuildings";
import { ManagerPropertyList } from "../components/propertyList/managerPropertyList";
import { RecalculateUnitSummary } from "../components/propertyList/reCalcUnitSummary";
import { setUnitBreakdown } from "../reducers/propertySlice";

const ManagerDashboard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const [portfolioBreakdown, setPortfolioBreakdown] = useState<any>({
    Residential: undefined,
    Commercial: undefined,
    Total: 0
  });

  const [portfolioBreakdownStats, setPortfolioBreakdownStats] = useState<any>({
    ResidentialPercentage: 0,
    CommercialPercentage: 0,
    ResBigger: 0
  });

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const unitSummary = RecalculateUnitSummary(
      properties.properties,
      properties.units
    );
    console.log(unitSummary);
    dispatch(setUnitBreakdown(unitSummary));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { updateBuildings, addThumbnail, addThumbnailLoaded, removeBuilding } =
    usePropertiesHook();
  const navigate = useNavigate();
  const properties = useSelector((state: any) => state.properties);
  const user = useSelector((state: any) => state.app.user);

  const RemoveBuilding = (building: any) => {
    removeBuilding({ property_id: building?.property_id });
  };

  useEffect(() => {
    if (
      !properties.loading &&
      !(portfolioBreakdown["Residential"] || portfolioBreakdown["Commercial"])
    ) {
      let tempBreakdown = portfolioBreakdown;

      RemoveTemplateProperties(properties.properties, RemoveBuilding);
      properties.properties.forEach((property: any) => {
        if (property.type) {
          if (property.type === "Residential") {
            if (!tempBreakdown["Residential"]) {
              tempBreakdown = { ...tempBreakdown, Residential: 1 };
              tempBreakdown = {
                ...tempBreakdown,
                Total: tempBreakdown["Total"] + 1
              };
            } else {
              tempBreakdown = {
                ...tempBreakdown,
                Residential: tempBreakdown["Residential"] + 1
              };
              tempBreakdown = {
                ...tempBreakdown,
                Total: tempBreakdown["Total"] + 1
              };
            }
          }

          if (property.type === "Commercial") {
            console.log("Setting Commercial");
            if (!tempBreakdown["Commercial"]) {
              tempBreakdown = { ...tempBreakdown, Commercial: 1 };
              tempBreakdown = {
                ...tempBreakdown,
                Total: tempBreakdown["Total"] + 1
              };
            } else {
              tempBreakdown = {
                ...tempBreakdown,
                Commercial: tempBreakdown["Commercial"] + 1
              };
              tempBreakdown = {
                ...tempBreakdown,
                Total: tempBreakdown["Total"] + 1
              };
            }
          }
        }
      });
      setPortfolioBreakdown(tempBreakdown);
      console.log(properties);
    }
  }, [properties]);

  useEffect(() => {
    let tempStats = portfolioBreakdownStats;
    if (
      !portfolioBreakdown["Residential"] &&
      !portfolioBreakdown["Commercial"]
    ) {
      tempStats = {
        ...tempStats,
        ResidentialPercentage: 50,
        CommercialPercentage: 50
      };
    } else if (!portfolioBreakdown["Residential"]) {
      tempStats = { ...tempStats, CommercialPercentage: 100 };
    } else if (!portfolioBreakdown["Commercial"]) {
      tempStats = { ...tempStats, ResidentialPercentage: 100, ResBigger: 1 };
    } else {
      const resPercent =
        (portfolioBreakdown["Residential"] / portfolioBreakdown["Total"]) * 100;
      if (resPercent > 50) {
        tempStats = {
          ...tempStats,
          ResidentialPercentage: resPercent,
          CommercialPercentage: 100 - resPercent,
          ResBigger: 1
        };
      } else {
        tempStats = {
          ...tempStats,
          ResidentialPercentage: resPercent,
          CommercialPercentage: 100 - resPercent,
          ResBigger: 0
        };
      }
    }

    setPortfolioBreakdownStats(tempStats);
  }, [portfolioBreakdown]);

  useEffect(() => {
    Object.keys(properties.buildingImages).forEach((buildingId: any) => {
      if (!properties.loadedThumbnails.includes(buildingId)) {
        addThumbnailLoaded(buildingId);
        loadThumbnail(
          properties.buildingImages[buildingId],
          buildingId,
          addThumbnail
        );
      }
    });
  }, [properties.buildingImages]);

  const navToDetails = (itemIndex: number) => {
    navigate(`/building-details/${itemIndex}`);
  };

  const onClickAddBuilding = () => {
    const formData = {
      ...submitFormTemplate,
      name: "No Name",
      property_id: Date.now().toString(),
      manager: user.agent_id,
      new_template: true
    };
    setTimeout(() => {
      updateBuildings(formData, "add");
      navigate(`/building-details/new_${formData["property_id"]?.toString()}`);
    }, 200);
  };

  return (
    <div className="container content-center mx-auto">
      <div>
        <Breadcrumbs />
        <PageTitle>Dashboard</PageTitle>
      </div>

      <div className="pt-8 ">
        <div
          className={`font-bold text-focus-blue ${
            windowWidth < 1260 && "text-center"
          }`}
        >
          Portfolio Breakdown
        </div>
        <div className="flex pl-6 flex-col xl:flex-row xl:items-center">
          <div
            className={`flex mt-3 items-center  h-[14rem] w-[100%] max-w-[28rem] rounded-2xl border-solid border-[2px] border-placeholder-gray-300 ${
              windowWidth > 1260 ? "mr-8 pl-4" : "flex-col h-[24rem]"
            }`}
          >
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className="relative"
            >
              <div
                style={
                  {
                    "--p": portfolioBreakdownStats["ResidentialPercentage"],
                    "--s": portfolioBreakdownStats["ResBigger"]
                  } as React.CSSProperties
                }
                className={`bg-pass-blue dashboard-pie h-[11rem] w-[11rem] rounded-full ${
                  isHovered ? "change" : "pieNormal"
                }`}
              ></div>

              <div
                className={`absolute inner-circle-parent top-0 right-0 translate-x-[-10%] translate-y-[10%] grid place-content-center bg-clarity h-[9rem] w-[9rem] rounded-full`}
              >
                <div className="grid place-content-center h-[8rem] w-[8rem] linear-pie rounded-full">
                  <div className="grid place-content-center h-[5rem] w-[5rem] bg-focus-blue rounded-full text-center">
                    <p className="block align-middle text-clarity text-3xl">
                      {portfolioBreakdown["Total"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[100%] pt-5 pl-12">
              <p className="font-bold text-focus-blue text-sm">By Percentage</p>
              <div className="flex pt-6">
                <p className="font-bold text-2xl text-pass-blue">
                  {portfolioBreakdown["Commercial"]
                    ? `${Math.round(
                        portfolioBreakdownStats["CommercialPercentage"]
                      )}`
                    : "0"}
                  %
                </p>
                <p className="pl-5 pt-1 font-bold text-edit-grey">Commercial</p>
              </div>
              <div className="flex pt-4">
                <p className="font-bold text-2xl text-delete-red">
                  {portfolioBreakdown["Residential"]
                    ? `${Math.round(
                        portfolioBreakdownStats["ResidentialPercentage"]
                      )}`
                    : "0"}
                  %
                </p>
                <p className="pl-5 pt-1 font-bold text-edit-grey">
                  Residential
                </p>
              </div>
            </div>
          </div>

          <div
            className={`flex mt-3 items-center pl-4 h-[14rem] w-[100%] max-w-[28rem] rounded-2xl border-solid border-[2px] border-placeholder-gray-300 ${
              windowWidth > 1260 ? "mr-8" : "flex-col"
            }`}
          >
            <div className="h-full w-[45%] flex justify-center items-center">
              <div className="grid place-content-center h-[5rem] w-[5rem] bg-clarity rounded-full text-center border-4 border-placeholder-gray-300">
                <p className="block align-middle text-focus-blue text-3xl">
                  {portfolioBreakdown["Total"]}
                </p>
              </div>
            </div>

            <div className="h-full pt-5">
              <p className="font-bold text-focus-blue text-sm">By Amount</p>
              <div className="flex pt-6">
                <p className="font-bold text-2xl text-pass-blue">
                  {portfolioBreakdown["Commercial"]
                    ? portfolioBreakdown["Commercial"]
                    : "0"}
                </p>
                <p className="pl-5 pt-1 font-bold text-edit-grey">Commercial</p>
              </div>
              <div className="flex pt-4">
                <p className="font-bold text-2xl text-delete-red">
                  {portfolioBreakdown["Residential"]
                    ? portfolioBreakdown["Residential"]
                    : "0"}
                </p>
                <p className="pl-[2.1rem] pt-1 font-bold text-edit-grey">
                  Residential
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <ManagerPropertyList
          buildings={properties.properties}
          onEditClick={navToDetails}
          addBuilding={onClickAddBuilding}
          windowWidth={windowWidth}
        />
      </div>
    </div>
  );
};

export default ManagerDashboard;
