import { useEffect, useState, useMemo } from "react";
import { connectTrixta } from "@trixtateam/trixta-js-core";
import jwtDecode from "jwt-decode";
import { setUser } from "../../reducers/appSlice";
import { useDispatch } from "react-redux";
import { useReadLocalStorage } from "usehooks-ts";

const AuthWrapper = ({ children }: { children?: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const agent = useReadLocalStorage<any>(
    `${process.env.REACT_APP_TRIXTA_SPACE as string}_agent`
  );
  const params = useMemo(() => {
    if (token) {
      return { token };
    }
    return;
  }, [token]);


  useEffect(() => {
    if (!loaded) return;
    dispatch(
      connectTrixta({
        space: process.env.REACT_APP_TRIXTA_SPACE as string,
        params
      })
    );
  }, [params, loaded, dispatch]);

  const refreshAuth = () => {
    if (agent && agent?.jwt && agent?.jwt !== token) {
      try {
        // agent = JSON.parse(localStorageAgent);
        const decoded: any = jwtDecode(agent?.jwt);
        if (decoded && decoded.exp > Date.now() / 1000) {
          dispatch(setUser(agent));
          setToken(agent?.jwt);
        } else {
          dispatch(setUser(undefined));
          setToken(null);
        }
      } catch (e) {
        console.log("Error parsing agent", e);
        return;
      }
    }

    if (!agent) {
      setToken(null);
      dispatch(setUser(undefined));
    }
  };

  useEffect(() => {
    refreshAuth();
    setLoaded(true);
  }, [agent, refreshAuth]);

  return <>{children}</>;
};

export default AuthWrapper;
