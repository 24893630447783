import servicesPie from "../../assets/Group 315.png"

export const RunningCosts = () => {
  return (
    <div className="flex">
      <img className="w-[50%] pr-8" src={servicesPie}/>

      <div className="w-[48%] rounded-3xl border-2">
        <div className="p-6">
          <div className="flex w-full pb-3">
            <p className="w-[10%] text-gray-400">a</p>
            <p className="w-[60%] text-focus-blue font-bold">Levies</p>
            <p className="text-gray-400">1423</p>
          </div>

          <div className="flex w-full pb-3">
            <p className="w-[10%] text-gray-400">b</p>
            <p className="w-[60%] text-focus-blue font-bold">Municipality</p>
            <p className="text-gray-400">423</p>
          </div>

          <div className="flex w-full pb-3">
            <p className="w-[10%] text-gray-400">c</p>
            <p className="w-[60%] text-focus-blue font-bold">Electricity</p>
            <p className="text-gray-400">231</p>
          </div>

          <div className="flex w-full pb-3">
            <p className="w-[10%] text-gray-400">d</p>
            <p className="w-[60%] text-focus-blue font-bold">Gas</p>
            <p className="text-gray-400">532</p>
          </div>

          <div className="flex w-full pb-3">
            <p className="w-[10%] text-gray-400">e</p>
            <p className="w-[60%] text-focus-blue font-bold">Fibre</p>
            <p className="text-gray-400">222</p>
          </div>

          <div className="flex w-full">
            <p className="w-[10%] text-gray-400">f</p>
            <p className="w-[60%] text-focus-blue font-bold">Security</p>
            <p className="text-gray-400">232</p>
          </div>

        </div>
      </div>
    </div>
  )
}