import TargetBar from "../../assets/Group 313.png";
import TargetBarHover from "../../assets/Group 314.png";
import { useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

export const RentTarget = () => {
  const [swapImg, setSwapImg] = useState(false);

  return (
    <div className="w-full">
      <img
        className="mb-14 w-[50%]"
        src={swapImg ? TargetBarHover : TargetBar}
        onMouseEnter={() => setSwapImg(true)}
        onMouseLeave={() => setSwapImg(false)}
      />

      <div className="h-44 rounded-2xl border-solid border-[2px] w-[50%]">
        <div className="px-8 pt-4">
          <div className="flex">
            <p className="mr-auto text-focus-blue font-bold">Graph Settings</p>
            <button className="">
              <PencilSquareIcon className="w-6 h-6 top-0 right-0 text-edit-grey" />
            </button>
          </div>

          <div className="flex pt-6">
            <div className="w-[50%] mr-6">
              <div className="flex">
                <p className="text-focus-blue font-bold">Minimum</p>
                <p className="ml-auto text-gray-400">0</p>
              </div>
              <div className="flex pt-6">
                <p className="text-focus-blue font-bold">Maximum</p>
                <p className="ml-auto text-gray-400">1 000 000</p>
              </div>
            </div>

            <div className="w-[50%] mr-4">
              <div className="flex">
                <p className="text-focus-blue font-bold">Target</p>
                <p className="ml-auto text-gray-400">50 000</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
