export const RemoveTemplateProperties = (properties: any, deleteFunction: any, setProperties?: any) => {
  properties.forEach((property: any) => {
    if (property.new_template) {
      deleteFunction(property)
    }
  })

  if (setProperties) {
    const filteredProperties = properties.filter((property: any) => !property.new_template);
    setProperties(filteredProperties);
  }
}
