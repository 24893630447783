import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { profileTemplate } from "./profileTemplates";

export const NormalView = ({ setEdit, profileData, profilePicture }: any) => {
  return (
    <div className="flex rounded-xl bg-field-grey h-72 relative">
      <button onClick={() => setEdit(true)}>
        <PencilSquareIcon className="edit-icon" />
      </button>

      {profilePicture ? (
        <img
          src={profilePicture}
          className="
                object-cover 
                rounded-l-xl
                w-[30%] h-72"
        />
      ) : (
        <div className="bg-hover-grey rounded-l-xl w-[30%] h-72 flex items-center justify-center">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      )}
      <div className="pt-8 pl-14 w-[70%]">
        {Object.keys(profileTemplate).map((key: any) => {
          return (
            <div key={key} className="flex">
              <div className="pb-4 w-[30%] flex text-clarity">
                <div className="pb-0.5">{profileTemplate[key].title}</div>
              </div>
              <div className="pb-4 w-[60%] flex text-edit-grey">
                <div className="pb-0.5">
                  {profileData[key] && profileData[key]}
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex">
          <div className="pb-4 w-[30%] flex text-clarity">
            <div className="pb-0.5">Contact Numbers</div>
          </div>
          <div className="pb-4 w-[60%] text-edit-grey">
            {profileData.contactNumbers &&
              profileData.contactNumbers.map((numbers: any, index: number) => {
                return (
                  <div key={index} className="pb-0.5">
                    ({numbers.name}) {numbers.number}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
