// export const RecalculateUnitSummary = (buildings: any, units: any) => {
//   const summary: any = {}

//   units.map((unit: any) => {
//     if (!summary[unit.type]) {
//       summary[unit.type] = 1
//     } else {
//       summary[unit.type] = summary[unit.type] + 1
//     }
//   })

//   const tempBuilding = {...building, units_types: summary}
//   updateBuildings(tempBuilding, "edit");
// }

export const RecalculateUnitSummary = (buildings: any, units: any) => {
  const summary: any = {}
  buildings.map((building: any) => {
    const tempSummary: any = {}
    if (units[building.property_id]) {
      units[building.property_id].forEach((unit: any) => {
        console.log(unit)
        if (!tempSummary[unit.type]) {
          tempSummary[unit.type] = 1
        } else {
          tempSummary[unit.type] = tempSummary[unit.type] + 1
        }
      })
      summary[building.property_id] = tempSummary
    }
  })
  console.log(summary)
  return summary
}