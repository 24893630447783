import { PencilSquareIcon } from "@heroicons/react/20/solid"
import { useState, useEffect } from "react"
import useUnitsHook from "../../hooks/useUnits"
import { unitTenantTemplate } from "../formTemplates/unitTemplates"

export const Tenants = ({ unitObj }: any) => {
  const [isAddingTenant, setIsAddingTenant] = useState(false)
  const [currentTenant, setCurrentTenant] = useState<any>(undefined)
  const [submitObj, setSubmitObj] = useState<any>(unitObj)
  const [checkNew, setCheckNew] = useState(false)

  const { editUnit } = useUnitsHook()

  // on submit update and edit unit object 
  const updateTenants = (e: any) => {
    console.log("updating tenants")
    console.log(e)
    e.preventDefault()
    if (!checkNew) {
      setSubmitObj((unitObj: any) => {
        const newArray = [...unitObj.tenants];
        newArray[currentTenant[1]] = currentTenant[0];

        return {...unitObj, tenants: newArray, images: []};
      })
    } else {
      setSubmitObj({...unitObj, tenants: [...unitObj.tenants, currentTenant[0]]})
    }
    //editUnit(unitObj)
  }

  useEffect(() => {
    if (isAddingTenant) {
      editUnit(submitObj)
      setIsAddingTenant(false)
    }
    
  }, [submitObj])

  // on field input, update current unit state
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setCurrentTenant((currentTenant: any) => {
      const newArray = [...currentTenant];
      newArray[0] = {...currentTenant[0], [name]: value};
      return newArray;
    })
  }

  // On delete click remove tenant from unit object 
  const handleDelete = () => {
    console.log("deleting tenant")
    const newTenantsList = unitObj.tenants.filter((item: any) => item.tenantId !== currentTenant[0].tenantId)
    setSubmitObj({...unitObj, tenants: newTenantsList})
  }

  return (
    <div className="pt-6 mb-20">
      <div className="flex justify-between items-start mb-4">
        <div className="text-focus-blue pr-4 text-lg font-semibold">Tenants</div>
        <div className="w-full h-4 border-b border-text-edit-grey">
        </div>

        <div className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey" ></div>
      </div>

      {!isAddingTenant ? <div>
        <div className="flex justify-between items-start mb-4 w-[80%] ml-5">
          <div className="flex items-center w-1/6">
            <div className="text-focus pr-4 text-sm font-semibold">Name</div>
            <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
          </div>

          <div className="flex items-center w-1/3">
            <div className="text-focus-blue px-4 text-sm font-semibold">Email</div>
            <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
          </div>

          <div className="flex items-center w-1/5">
            <div className="text-focus-blue px-4 text-sm font-semibold">Start Date</div>
            <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
          </div>

          <div className="flex items-center w-1/5">
            <div className="text-focus px-4 text-sm font-semibold">End Date</div>
            <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
          </div>

          <div className="flex items-center w-1/5">
            <div className="text-focus pl-4 text-sm font-semibold">Rent Status</div>
          </div>
        </div>
      
        {submitObj.tenants && submitObj.tenants.map(
          (tenant: any, index: number) => {
            return (
              <div className="flex" key={index}>
                <div className="flex justify-between items-start mb-4 w-[80%] ml-5 text-edit-grey">
                  <div className="flex items-center w-1/6">
                    <div className="text-focus pr-4 text-sm">{tenant.name ? tenant.name : <>N/A</>}</div>
                  </div>

                  <div className="flex items-center w-1/3">
                    <div className="px-4 text-sm">{tenant.email ? tenant.email : <>N/A</>}</div>
                  </div>

                  <div className="flex items-center w-1/5">
                    <div className="px-4 text-sm">{tenant.startDate ? tenant.startDate : <>N/A</>}</div>
                  </div>

                  <div className="flex items-center w-1/5">
                    <div className="text-focus px-4 text-sm">{tenant.endDate ? tenant.endDate : <>N/A</>}</div>
                  </div>

                  <div className="flex items-center w-1/5">
                    <div className="text-focus pl-4 text-sm">{tenant.rentStatus ? tenant.rentStatus : <>N/A</>}</div>
                  </div>
                </div>
                <div className="ml-auto">

                  <button className="flex mr-4">
                    <PencilSquareIcon onClick={() => {
                      setCurrentTenant([tenant, index]) // set current tenant state
                      setCheckNew(false)
                      setIsAddingTenant(true)
                    }} className="w-6 h-6 text-edit-grey self-middle" />
                  </button>
                </div>
              </div>
            )
          })}

          <div className="flex ml-5 pt-6 items-center">
            <div className="pr-2 text-2xl">+</div>
            <div className="text-xs font-semibold pt-0.5">
              <button onClick={() => {
                console.log(unitTenantTemplate)
                const newUnit = {...unitTenantTemplate, tenantId: Date.now().toString()}
                setCurrentTenant([newUnit, "new"]) // set current unit state using template (new)
                setCheckNew(true)
                setIsAddingTenant(true)
              }}>ADD TENANT</button>
            </div>
          </div> 
        </div> : 
        
        <form className="ml-5" onSubmit={updateTenants}>
          <div className="w-[50%]">
            <div className="flex">
              <div className="w-[25%] text-field-grey font-semibold text-sm">Name</div>
              <input className="w-[75%] edit-fields" name="name" onChange={onChange} value={currentTenant[0].name} />
            </div>

            <div className="flex pt-3">
              <div className="w-[25%] text-field-grey font-semibold text-sm">Email</div>
              <input className="w-[75%] edit-fields" name="email" onChange={onChange} value={currentTenant[0].email} />
            </div>

            <div className="flex pt-9">
              <div className="w-[25%] text-field-grey font-semibold text-sm">Start Date</div>
              <input className="w-[75%] edit-fields" name="startDate" type="date" onChange={onChange} value={currentTenant[0].startDate} />
            </div>

            <div className="flex pt-3">
              <div className="w-[25%] text-field-grey font-semibold text-sm">End Date</div>
              <input className="w-[75%] edit-fields" name="endDate" type="date" onChange={onChange} value={currentTenant[0].endDate} />
            </div>

            <div className="flex pt-3">
              <div className="w-[25%] text-field-grey font-semibold text-sm">Rent Status</div>
              <input className="w-[75%] edit-fields" name="rentStatus" onChange={onChange} value={currentTenant[0].rentStatus} />
            </div>
          </div>
          <div className="flex mt-6 justify-between w-[50%]">
            <button className="px-4 py-0.5 rounded-full bg-delete-red text-clarity" onClick={() => setIsAddingTenant(false)}>
              CANCEL
            </button>
            <button className="bg-pass-blue px-7 py-0.5 rounded-full text-clarity" type="submit">
              SAVE
            </button>
            <button className="px-4 py-0.5 rounded-full bg-delete-red text-clarity" type="button" onClick={() => handleDelete()}>
              DELETE
            </button>
          </div>
        </form>
        } 
      
    </div>
  )
}