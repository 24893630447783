import { Decode } from "./decode";

export const loadImages = (images: any, setImages: any, setImagesLoaded?: any) => {
  const loadImages = async () => {
    const allData: string[] = await Promise.all(images.map(async (image: any, index: number) => {
      if (typeof image[1] === "string") {
        return image
      }

      const imageDecoded = Decode(image[1])
      const imageNameDecoded = Decode(image[0])
      // const response = await fetch(imageDecoded);
      // const base64Image = await response.text();
      return [imageNameDecoded, imageDecoded];
    }));
    
    console.log(allData)
    setImages(allData);
    if (setImagesLoaded) {
      setImagesLoaded(true)
    }
  }
  loadImages()
}

export const loadSingleImage = (image: any, setImage: any, setImageLoaded?: any) => {
  const loadImages = async () => {
    const allData = new Promise((resolve) => {
      if (typeof image === "string") {
        setImage(image);
        return image
      }

      const imageDecoded = Decode(image)
      setImage(imageDecoded);
      resolve("done")
    });
    allData.then()
    if (setImageLoaded) {
      setImageLoaded(true)
    }
  }
  loadImages()
}