import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../components/breadcrumbs";
import { PageTitle } from "../components/typography";
import { useEffect } from "react";

const ServicesEvent = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container mx-auto p-6 h-[52rem]">
        <button
          className="ml-auto pr-4 pl-2 rounded-lg flex items-center transition duration-200 ease-in-out hover:bg-hover-grey"
          onClick={() => {
            navigate("/services");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            ></path>
          </svg>
          <div className="text-xs font-semibold text-focus-blue pl-2">
            RETURN
          </div>
        </button>
        <Breadcrumbs />
        <PageTitle className="flex flex-row gap-[1rem]">
          Event A
          <p className="font-bold text-edit-grey text-lg mt-[0.28rem]">ID640</p>
        </PageTitle>

        <div className="border-4 h-[8rem] min-w-[60rem] max-w-[60rem] mt-6 mx-[2rem] rounded-xl mb-[3rem]">
          <hr className="border-t-4 mt-[2.7rem]" />
          <div className="relative left-[0.8rem] bottom-[2.9rem] flex flex-row mt-1 text-xl text-smarthost-navy font-bold gap-[9.3rem] ml-4">
            <h3 className="text-lg whitespace-nowrap">Date</h3>
            <h3 className="text-lg whitespace-nowrap">Service ID</h3>
            <h3 className="text-lg whitespace-nowrap">Type</h3>
            <h3 className="text-lg whitespace-nowrap">Property</h3>
            <h3 className="text-lg whitespace-nowrap">Cost</h3>
          </div>
          <div className="relative bottom-[2.9rem] flex flex-row text-lg text-smarthost-navy gap-[8rem] mt-[3rem]">
            <p className="whitespace-nowrap ml-[0.9rem]">16/03/2023</p>
            <p className="whitespace-nowrap mr-[0.9rem]">ID 640</p>
            <p className="whitespace-nowrap">Plumbing</p>
            <p className="whitespace-nowrap">Turtle</p>
            <p className="whitespace-nowrap mr-[0.8rem]">R1000.12</p>
          </div>
        </div>

        <h2 className="mt-[-2rem] pb-5 text-xl font-bold border-solid tracking-wide text-smarthost-navy">
          Detailed Breakdown
        </h2>
        <div className="flex flex-column">
          <div className="relative bottom-[3rem] flex flex-row mt-[4rem] text-smarthost-navy gap-[5rem]">
            <img
              src="https://i.imgur.com/s3unnWL.png"
              className="ml-[1rem] mr-[-3.5rem] h-[50px] w-[50px]"
            />
            <h3 className="text-lg font-bold whitespace-nowrap mt-[0.8rem] mr-[-3rem]">
              Start Time
            </h3>
            <p className=" text-edit-grey font-bold mt-[0.95rem]">12:19</p>
            <img
              src="https://i.imgur.com/y7jUvhD.png"
              className="mr-[-3.5rem] h-[48px] w-[58px]"
            />
            <h3 className="text-lg font-bold whitespace-nowrap mt-[0.7rem]">
              End Time
            </h3>
            <p className=" text-edit-grey font-bold mt-[0.8rem] ml-[-2.7rem]">
              12:19
            </p>
          </div>
          <div className="ml-[-34.1rem] flex flex-row mt-[6rem] text-smarthost-navy gap-[2rem]">
            <img
              src="https://i.imgur.com/JVPmB56.png"
              className="h-[40px] w-[40px]"
            />
            <h3 className="text-lg font-bold ">Service Provider</h3>
            <p className="whitespace-nowrap text-edit-grey font-bold mt-[0.17rem] ml-[-0.3rem]">
              Z-Services
            </p>
          </div>
          <div className="flex flex-row mt-[10rem] ml-[-19.4rem] text-smarthost-navy gap-[2rem]">
            <img
              src="https://i.imgur.com/hrLyEjD.png"
              className=" ml-[0.3rem] h-[45px] w-[45px]"
            />
            <h3 className="text-lg font-bold mt-[0.5rem] w-[10rem] ml-[-0.3rem]">
              Event Logger
            </h3>
            <p className="whitespace-nowrap text-edit-grey font-bold ml-[-3rem] mt-[0.6rem]">
              Satoshi
            </p>
          </div>
        </div>

        <h2 className=" mt-[0.8rem] pb-2 text-xl font-bold border-solid tracking-wide text-smarthost-navy">
          Log
        </h2>
        <div className="relative bottom-[13rem]">
          <div className="flex flex-row mt-[13rem] ml-[3rem]">
            <h3 className="text-lg font-bold text-smarthost-navy">Actor</h3>
            <h3 className="text-lg font-bold ml-[7rem] text-smarthost-navy">
              Message
            </h3>
            <h3 className="text-lg font-bold ml-[36.5rem] text-smarthost-navy">
              Time
            </h3>
          </div>
          <div className="border-2 ml-[3rem] h-[10rem] min-w-[55rem] max-w-[55rem] mt-4 rounded-[4px] bg-neutral-50 flex flex-row">
            <div className="flex flex-col">
              <div className=" bg-gray-500 h-[3.33rem] w-[2rem]" />
              <div className=" bg-smarthost-cyan h-[3.33rem] w-[2rem]" />
              <div className=" bg-smarthost-coral h-[3.33rem] w-[2rem]" />
            </div>
            <div className="flex">
              <div className=" mt-[3.16rem] ml-[em] pt-[3.2rem] w-[52.7rem] h-[3.33rem] border-t-2 border-b-2 opacity-30 bg-white" />
              <div className="absolute flex flex-col border-r-2 w-[8rem] h-[9.9rem] mt-[] ml-[0.8rem] gap-[1.9rem]">
                <p className="mt-[0.6rem] text-gray-500 font-bold">Joe Lucky</p>
                <p className="text-smarthost-cyan font-bold">AI</p>
                <p className="text-smarthost-coral font-bold">Host</p>
              </div>
              <div className="flex flex-col absolute mt-[0.8rem] ml-[10rem] gap-[1.9rem] w-[8rem] h-[9.9rem]">
                <p className=" w-[20rem] text-gray-500 font-bold">
                  My toilet is clogged.
                </p>
                <p className="w-[20rem] text-smarthost-cyan font-bold">
                  Contacting Host. Please hold.
                </p>
                <p className=" w-[20rem] text-smarthost-coral font-bold">
                  Calling plumber.
                </p>
              </div>
              <div className="relative right-[4rem] flex flex-col gap-[1.9rem] mt-[0.8rem] h-[9.9rem]">
                <p className="text-gray-500">19:20</p>
                <p className="text-gray-500">19:40</p>
                <p className="text-gray-500">20:00</p>
              </div>
              <div className="absolute min-w-[1rem] w-[48rem] h-[9.85rem] border-r-2" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesEvent;
