import { useNavigate } from "react-router-dom";

interface BreadcrumbsProps {
  page?: any;
  building?: string;
  building_id?: string;
}


export const Breadcrumbs = ({ page, building, building_id }: BreadcrumbsProps) => {
  const navigate = useNavigate()
  return (
    <div className="text-xs font-semibold text-unfocused-grey mb-1 flex">
      <button className="pr-2" onClick={() => navigate(`/`)}>Home</button>
      {(page) && 
        <button className="pr-2" onClick={() => navigate(`/`)}>
          {page}
        </button>
      }
      {(building) && 
        <>
          <button onClick={() => navigate(`/building-details/${building_id}`)}>{building}</button>
        </>
      }
    </div>
  );
};
