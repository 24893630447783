import useUnitsHook from "../../hooks/useUnits";

export const UnitEditCommercials = ({ currentUnit, setCurrentUnit, setEditing }: any) => {

  const { editUnit } = useUnitsHook()

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setCurrentUnit({
      ...currentUnit,
      commercials: {
        ...currentUnit.commercials,
        [name]: value
      },
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    console.log(currentUnit)
    editUnit(currentUnit)
    setEditing(false)
  }


  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-rows-4 grid-flow-col w-[100%]">
        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Purchase Price
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
            <input name="purchasePrice" value={currentUnit.commercials.purchasePrice} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Purchase Date
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="purchaseDate" value={currentUnit.commercials.purchaseDate} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Current Market Value
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="currentMarketValue" value={currentUnit.commercials.currentMarketValue} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Entry Costs
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="entryCosts" value={currentUnit.commercials.entryCosts} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Total entry Costs
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="totalEntryCosts" value={currentUnit.commercials.totalEntryCosts} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Monthly Running Costs
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="monthlyRunningCosts" value={currentUnit.commercials.monthlyRunningCosts} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Annual Rent Price Targets
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="annualRentPriceTarget" value={currentUnit.commercials.annualRentPriceTarget} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Rental Terms Prices
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="rentalTermsPrices" value={currentUnit.commercials.rentalTermsPrices} className="edit-fields" onChange={onChange}/>
          </div>
        </div>
      </div>
      <div className="flex pr-6 pt-2 justify-end">
        <button className="bg-pass-blue px-7 py-0.5 rounded-full" type="submit">SAVE</button>
      </div>
    </form>
  )
}