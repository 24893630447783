const TestData = {
        "date": [
            "16/03/2023",
            "14/03/2023",
            "13/03/2023",
            "12/03/2023",
            "11/03/2023",
            "10/03/2023",
            "9/03/2023",
            "8/03/2023",
            "7/03/2023",
            "5/03/2023"
        ],
        "serviceID": [
            "ID5231",
            "ID5342",
            "ID5620",
            "ID8640",
            "ID9340",
            "ID9229",
            "ID5021",
            "ID4732",
            "ID6521",
            "ID4254"
        ],
        "serviceType": [
            "Plumbing",
            "Cleaning",
            "Washing",
            "Cleaning",
            "Plumbing",
            "Plumbing",
            "Plumbing",
            "Washing",
            "Washing",
            "Plumbing"
        ],
        "property": [
            "Tiger",
            "Panda",
            "Panda",
            "Tiger",
            "Turtle",
            "Tiger",
            "Turtle",
            "Panda",
            "Panda",
            "Turtle"
        ],
        "cost": [
            "R200",
            "R400",
            "R700",
            "R100",
            "R1005",
            "R1005",
            "R1005",
            "R1005",
            "R1005",
            "R1005",
        ],
        "status": [
            "Pending",
            "Cancelled",
            "Pending",
            "Pending",
            "Resolved",
            "Pending",
            "Resolved",
            "Resolved",
            "Resolved",
            "Resolved"
        ]
}

export default TestData