const Debug = ({ json }: { json: any }) => {
  if (json)
    return (
      <pre className="text-left text-sm text-white bg-slate-700 px-4 py-2 rounded-md mb-2 whitespace-pre-wrap h-96 overflow-auto">
        {JSON.stringify(json, null, 2)}
      </pre>
    );
  return null;
};

export default Debug;
