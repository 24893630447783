export const ManagerUnitSummary = (propertyList: any) => {
  const types: string[] = [];
  propertyList.map((prop: any) => {
    if (prop.units_types) {
      Object.keys(prop.units_types).map((unitType) => {
        if (!types.includes(unitType)) {
          types.push(unitType)
        }
      })
    }
  })

  return (types)
}