import { Decode } from "./decode"
import { setLoadedThumbnail } from "../../reducers/propertySlice"

export const loadThumbnail = async (imageProp: any, propId: any, dispatch: any) => {
  let tempObj: any;
  
  if (imageProp.length === 0) {
    tempObj = {propertyId: propId, property: "empty"}
    dispatch(setLoadedThumbnail(tempObj))
    return
  }
  const mediaName = Decode(imageProp[0][0])
  if (mediaName !== "thumbnail") {
    return
  }

  const image = imageProp[0][1]
  if (typeof imageProp[0][1] === "string") {
    tempObj = {propertyId: propId, property: imageProp[0][1]}
    dispatch(setLoadedThumbnail(tempObj))
    return
  }
  const res = Decode(image)
  // const response = await fetch(res);
  // const base64Image = await response.text();
  tempObj = {propertyId: propId, property: res}
  dispatch(setLoadedThumbnail(tempObj))
}