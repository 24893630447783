import usePropertiesHook from "../../hooks/useProperties"
import useUnitsHook from "../../hooks/useUnits"
import { useState } from "react"


export const EditDescription = ({ setEdit, propertyObj, setPropertyObj }: any) => {
  const { updateBuildings } = usePropertiesHook()
  const { editUnit } = useUnitsHook()
  const [submitObj, setSubmitObj] = useState(propertyObj)


  const onSubmit = (e: any) => {
    e.preventDefault()
    if (submitObj.unit_id) {
      editUnit(submitObj)
    } else {
      updateBuildings(submitObj, "edit")
    }

    console.log(submitObj.specifications.description)
    
    setPropertyObj({
      ...propertyObj,
      specifications: {
        ...propertyObj.specifications,
        description: submitObj.specifications.description
      },
    })
    setEdit(false)
  }

  const onChange = (e: any) => {
    console.log(e.target.value)
    setSubmitObj({
      ...propertyObj,
      specifications: {
        ...propertyObj.specifications,
        description: e.target.value
      },
    })
  }

  return (
    <div className="pt-6">
      <div className="flex justify-between items-start mb-4">
        <div className="text-focus-blue pr-4 text-lg font-semibold">Description</div>
        <div className="w-full h-4 border-b border-text-edit-grey">
        </div>
        <div className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey"></div>
      </div>

      <form 
        className="
          text-edit-grey 
          pl-5 w-[95%] h-60
          rounded-2xl 
          overflow-auto 
          break-words 
          border-pass-blue 
          border-2 pt-4"
        onSubmit={onSubmit}
        >
        <textarea className="pt-5 px-2 w-full h-[79%] resize-none outline-none overflow-y-auto"  value={submitObj.specifications.description} onChange={onChange}/>
        <div className="h-[20%] w-full flex justify-end">
          <button 
            className="text-sm px-10 mr-2 my-2 bg-pass-blue text-clarity rounded-full" 
            type="submit">
              SAVE
          </button>
          <button 
            className="text-sm w-28 mr-2 my-2 bg-delete-red text-clarity rounded-full" 
            type="submit"
            onClick={() => setEdit(false)}>
              CANCEL
          </button>
        </div>
      </form>
      
    </div>
  )
}

export const EditFeatures = ({ setEdit, propertyObj, setPropertyObj }: any) => {
  const { updateBuildings } = usePropertiesHook()
  const { editUnit } = useUnitsHook()
  const [submitObj, setSubmitObj] = useState(propertyObj)


  const onSubmit = (e: any) => {
    e.preventDefault()
    setSubmitObj({...submitObj, new_template: false});
    if (submitObj.unit_id) {
      console.log(submitObj)
      editUnit(submitObj)
    } else {
      console.log(submitObj)
      updateBuildings(submitObj, "edit")
    }
    setPropertyObj({
      ...propertyObj,
      specifications: {
        ...propertyObj.specifications,
        features: submitObj.specifications.features
      },
    })
    setEdit(false)
  }

  const onChange = (e: any) => {
    setSubmitObj({
      ...propertyObj,
      specifications: {
        ...propertyObj.specifications,
        features: e.target.value
      },
    })
  }

  return (
    <div className="pt-6">
      <div className="flex justify-between items-start mb-4">
        <div className="text-focus-blue pr-4 text-lg font-semibold">Features</div>
        <div className="w-full h-4 border-b border-text-edit-grey">
        </div>
        <div className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey"></div>
      </div>

      <form 
        className="
          text-edit-grey 
          pl-5 w-[95%] h-60
          rounded-2xl 
          overflow-auto 
          break-words 
          border-pass-blue 
          border-2 pt-4"
        onSubmit={onSubmit}
        >
        <textarea className="pt-5 px-2 w-full h-[79%] resize-none outline-none overflow-y-auto"  value={submitObj.specifications.features} onChange={onChange}/>
        <div className="h-[20%] w-full flex justify-end">
          <button 
            className="text-sm w-28 mr-2 my-2 bg-pass-blue text-clarity rounded-full" 
            type="submit">
              SAVE
          </button>
          <button 
            className="text-sm w-28 mr-2 my-2 bg-delete-red text-clarity rounded-full"
            onClick={() => setEdit(false)}>
              CANCEL
          </button>
        </div>
        
      </form>
      
    </div>
  )
}