import { useState } from "react";
import { PropertyValue } from "./propertyValue";
import { RentTarget } from "./rentTarget";
import { ServicesTab } from "./servicesTab";
import { RunningCosts } from "./runningCosts";
import { CommercialsNav } from "./commericalsNav";

export const UnitCommercialsTabs = ({ unitObj }: any) => {
  const [currentTab, setCurrentTab] = useState("propertyValue");

  return (
    <div className="pt-12 pb-4">
      <div className="pb-10 w-[50%]">
        <CommercialsNav setTab={setCurrentTab} tab={currentTab} />
      </div>
      {currentTab == "propertyValue" ? (
        <PropertyValue unitObj={unitObj} />
      ) : currentTab == "rentTarget" ? (
        <RentTarget />
      ) : currentTab == "services" ? (
        <ServicesTab />
      ) : (
        <RunningCosts />
      )}
    </div>
  );
};
