import usePropertiesHook from "../../hooks/useProperties"
import useUnitsHook from "../../hooks/useUnits"
import { useState, useEffect, useRef } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"


export const Tags = ({ tags, propertyObj, setPropertyObj, propertyType }: any) => {
  const [addingTag, setAddingTag] = useState(false)
  const [submitProperty, setSubmitProperty] = useState(propertyObj)
  const [firstRun, setFirstRun] = useState(true)
  const [firstRunSubmit, setFirstRunSubmit] = useState(true)
  const [currentTags, setCurrentTags] = useState(tags)
  const { updateBuildings } = usePropertiesHook()
  const { editUnit } = useUnitsHook()

  const addTagInp: any = useRef()

  useEffect(() => {
    if (addingTag) {
      addTagInp.current.focus();
    }
  }, [addingTag])

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false)
    } else {
      setSubmitProperty({...propertyObj, images: [], tags: [...currentTags], new_template: false});
    }
  }, [currentTags])

  useEffect(() => {
    if (firstRunSubmit) {
      setFirstRunSubmit(false)
      return
    }

    console.log("updating")
    if (propertyType === "building") {
      updateBuildings(submitProperty, "edit")
    } else {
      editUnit(submitProperty)
    }
  }, [submitProperty])

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setAddingTag(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);


  const handleTagSubmit = (e: any) => {
    e.preventDefault();
    setCurrentTags([...currentTags, e.target[0].value])
    setAddingTag(false)
  }

  const handleDelete = (index: number) => {
    console.log(currentTags)
    setCurrentTags((currentTags: string[]) => currentTags.filter((item, i) => i !== index))
  }


  return (
    <div className="pt-6 relative">
      <div className="flex justify-between items-start mb-4">
        <div className="text-focus-blue text-lg font-semibold">Tags</div>
      </div>
      <div className="flex flex-row flex-wrap">
        {currentTags.map((tag: any, index: any) => {
          return (
            <div key={index} className="flex group mr-2 mt-2 px-2.5 py-0.5 rounded-full bg-delete-red text-clarity pt-[4px]">
              <div >
                {tag}
              </div>

              <button>
                <XMarkIcon onClick={() => handleDelete(index)} className="w-5 h-5 self-center ml-1 hidden group-hover:block" />
              </button>
            </div>
          )
        })}
        {!addingTag ?
        <button onClick={() => setAddingTag(true)} className="mr-2 mt-2 px-3 py-1 mb-[2px] rounded-full bg-delete-red text-clarity">
          <div>+</div>
        </button> : 
        <form onSubmit={handleTagSubmit}>
          <input name="tag" ref={addTagInp} className="mr-2 mt-2 px-2.5 py-1 rounded-full bg-delete-red text-clarity pb-[6px] outline-none" />
        </form>}
      </div>
    </div>
  )
}