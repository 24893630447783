import { createSlice } from "@reduxjs/toolkit";

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    errors: {},
    responses: {}
  },
  reducers: {
    setResponse: (state: any, payload: any) => {
      console.log("setResponse", payload);
      const role = payload.payload.trixtaMeta.roleName;
      const modifier =
        payload.payload.trixtaMeta.actionName ||
        payload.payload.trixtaMeta.reactionName;
      if (!role || !modifier) return;

      state.responses[`${role}:${modifier}`] = payload.payload;
      delete state.errors[`${role}:${modifier}`];
    },
    setError: (state: any, payload: any) => {
      console.log("setError", payload);
      if (!payload.payload.trixtaMeta) return;
      const role = payload.payload.trixtaMeta.roleName;
      const modifier =
        payload.payload.trixtaMeta.actionName ||
        payload.payload.trixtaMeta.reactionName;
      state.errors[`${role}:${modifier}`] = payload.payload;
      delete state.responses[`${role}:${modifier}`];
    },
    clear: (state: any, payload: any) => {
      console.log("clear", payload);
      const role = payload.payload.trixtaMeta.roleName;
      const modifier =
        payload.payload.trixtaMeta.actionName ||
        payload.payload.trixtaMeta.reactionName;

      if (!payload.payload.trixtaMeta) return;
      delete state.errors[`${role}:${modifier}`];
      delete state.responses[`${role}:${modifier}`];
    }
  }
});

export const { setError, clear, setResponse } = roleSlice.actions;

export default roleSlice.reducer;
