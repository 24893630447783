import { FieldProps } from "@rjsf/utils";
import React, { useEffect, useRef, useCallback } from "react";
import { get } from "../../../utils";

// Inject google API client script if google auth action is available on this space
const loadGoogleApi = (callback: any) => {
  const existingScript = document.getElementById("googleApi");
  if (!existingScript) {
    const script = document.createElement("script");
    script.setAttribute("id", "googleApi");
    script.setAttribute("src", "https://accounts.google.com/gsi/client");
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export function GoogleAuthField({
  idSchema,
  onChange,
  formData = "",
  name
}: FieldProps) {
  const tokenRef = useRef<HTMLInputElement>(null);
  const [clientId, setClientId] = React.useState<string>("");

  /**
   * Handle Google auth response to set token and trigger
   * form submit to send google token to Trixta for authorization
   * @param response {object} Google auth response
   */
  const handleCredentialResponse = useCallback(
    (response: any) => {
      if (response.credential) {
        onChange(response.credential);
        const currentForm = tokenRef.current?.closest("form");

        // submit form
        const submitEvnt = new Event("submit", { bubbles: true });
        currentForm?.dispatchEvent(submitEvnt);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setClientId(formData);
  }, [formData]);

  useEffect(() => {
    if (!clientId) return;

    loadGoogleApi(() => {
      // Calback function to handle initialization of Google Sign-In
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse
      });

      // render the Google Sign-In button
      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("googleAuthButton"),
        { theme: "outline", size: "large" } // customization attributes
      );
    });

    return () => {
      // clean up on unmount
      // @ts-ignore
      google.accounts.id.cancel();
      const script = document.getElementById("googleApi");
      if (script?.parentElement) script.parentElement.removeChild(script);
    };
  }, [clientId, handleCredentialResponse]);

  return (
    <div style={{ margin: "10px auto" }}>
      <input
        ref={tokenRef}
        id={get(idSchema, "$id")}
        type="hidden"
        name={name}
        value=""
      />
      <div id="googleAuthButton"></div>
    </div>
  );
}
