import { FieldProps } from "@rjsf/utils";
import React from "react";


export function TextLink({ schema, formData = "" }: FieldProps) {
  const { title } = schema;

  return (
    <div className="flex flex-col mb-2">
      <a
        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        href={formData}
      >
        {title}
      </a>
    </div>
  );
}
