import { useState } from "react"
import { PencilSquareIcon } from "@heroicons/react/20/solid"
import { UnitEditCommercials } from "../formEditComponants/unitEditCommercials"

export const UnitCommercials = ({ unitObj, setUnitObj}: any) => {
  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false)

  return (
    <div>
      <div className="py-6 w-[100%] ">
        <div className="flex justify-between items-start mb-4">
          <div className="text-focus-blue pr-4 text-lg font-semibold">Unit Commercials</div>
          <div className="w-full h-4 border-b border-text-edit-grey">
          </div>
          <button onClick={() => setIsEditingDescription(true)}>
            <PencilSquareIcon className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey" />
          </button>
        </div>
        {(!isEditingDescription) ? 
        <div className="grid grid-rows-4 grid-flow-col w-[100%]">
          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Purchase Price
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.purchasePrice ? unitObj.commercials.purchasePrice : <>N/A</>}
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Purchase Date
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.purchaseDate ? unitObj.commercials.purchaseDate : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Current Market Value
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.currentMarketValue ? unitObj.commercials.currentMarketValue : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Entry Costs
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.entryCosts ? unitObj.commercials.entryCosts : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Total entry Costs
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.totalEntryCosts ? unitObj.commercials.totalEntryCosts : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Monthly Running Costs
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5 overflow-auto break-words w-[50%]">
                {unitObj.commercials.monthlyRunningCosts ? unitObj.commercials.monthlyRunningCosts : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Annual Rent Price Targets
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.annualRentPriceTarget ? unitObj.commercials.annualRentPriceTarget : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
                Rental Terms Prices
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.commercials.rentalTermsPrices ? unitObj.commercials.rentalTermsPrices : <>N/A</>}
                
              </div>
            </div>
          </div>
        </div> : 
        <UnitEditCommercials currentUnit={unitObj} setCurrentUnit={setUnitObj} setEditing={setIsEditingDescription} />}
      </div>
    </div>
  )
}