/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from "@reduxjs/toolkit";
import { phoenixReducer } from "@trixtateam/phoenix-to-redux";
import { trixtaReducer } from "@trixtateam/trixta-js-core";

import appReducer from "./appSlice";
import loginSlice from "./loginSlice";
import roleSlice from "./roleSlice";
import verifySlice from "./verifySlice";
import propertiesSlice from "./propertySlice";

export default function createReducer() {
  const rootReducer = combineReducers({
    trixta: trixtaReducer,
    phoenix: phoenixReducer,
    app: appReducer,
    login: loginSlice,
    role: roleSlice,
    verify: verifySlice,
    properties: propertiesSlice
  });
  return rootReducer;
}
