import { PropertyValueTabel } from "./propertyValueTabel";
import { PropertyValueChart } from "./propertyValueChart";
import { useState } from "react";

export const PropertyValue = ({ unitObj }: any) => {
  const [currentYear, setCurrentYear] = useState<number>(2023);
  const [propertyValues, setPropertyValues] = useState<any>(
    unitObj.propertyValues || {}
  );

  const [dateRange, setDateRange] = useState<string>("year");

  console.log(dateRange);

  return (
    <div className="flex justify-between">
      <div className="flex flex-col items-end font-bold w-[50%]">
        <div className="flex w-[90%] pb-6">
          <div className="border-l-8 border-pass-blue h-5 pr-1 self-center"></div>
          <p className="text-focus-blue text-2xl">R1100</p>
          <p className="text-focus-blue text-lg ml-auto self-center">2019</p>
        </div>
        <div className="w-[100%] h-full">
          {/* <img className="" src={PropertyValueGraph}></img> */}
          <PropertyValueChart
            propertyValue={propertyValues[currentYear]}
            dateRange={dateRange}
          />
        </div>
        <div className="flex w-[90%] pt-10 text-sm text-focus-blue ">
          <p className="self-center">Date Range</p>
          <div className="relative inline-flex pl-4">
            <svg
              className="w-2 h-2 absolute top-0 right-0 m-2 transform translate-y-[25%] pointer-events-none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 412 232"
            >
              <path
                d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.763-9.763 25.592 0 35.355l181 181c9.763 9.762 25.592 9.762 35.355 0l181-181c9.762-9.763 9.762-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                fill="#648299"
              />
            </svg>
            <select
              defaultValue="year"
              className="text-sm text-pass-blue bg-pass-blue/10 h-7 pl-5 pr-10 appearance-none focus:outline-none"
              onChange={e => setDateRange(e.target.value)}
            >
              <option value="month" className="border-0">
                6 Months
              </option>
              <option value="year">1 Year</option>
            </select>
          </div>
        </div>
      </div>

      <PropertyValueTabel
        unitObj={unitObj}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
        propertyValues={propertyValues}
        setPropertyValues={setPropertyValues}
      />
    </div>
  );
};
