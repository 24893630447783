import { LOGIN_TRIXTA_SUCCESS, updateTrixtaRole } from "@trixtateam/trixta-js-core";
import { put, takeLatest } from 'redux-saga/effects';

/**
 * Get the roles of the agent and try join each roles
 */
function* joinRolesSaga({ payload }: any) {
  const { trixtaMeta } = payload;
    if (trixtaMeta && trixtaMeta.roles && trixtaMeta.roles.length !== 0) {
      for (const role of trixtaMeta.roles) {
        // dont connect to trixta_ide_user
        console.log(trixtaMeta.roles)
        if(role !== "trixta_ide_user") {
          console.log(`setting ${role}`)
          yield put(updateTrixtaRole({ name: role}));
        }
      }
    }
}

/**
 * Success Saga based on the login, we need to wire up a function and setup types
 */
export function* authSuccessSaga() {
  yield takeLatest<{ type: string; payload: any}>(LOGIN_TRIXTA_SUCCESS, joinRolesSaga);
}

