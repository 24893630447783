import useUnitsHook from "../../hooks/useUnits";

export const UnitEditOperations = ({ currentUnit, setCurrentUnit, setEditing }: any) => {

  const { editUnit } = useUnitsHook()

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setCurrentUnit({
      ...currentUnit,
      operations: {
        ...currentUnit.operations,
        [name]: value
      },
    })
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    console.log(currentUnit)
    editUnit(currentUnit)
    setEditing(false)
  }


  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-rows-4 grid-flow-col w-[100%]">
        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
              Furniture pack 
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
            <input name="furniture" value={currentUnit.operations.furniture} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            Furniture pack Type
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="packType" value={currentUnit.operations.packType} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            Door lock type 
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="doorLock" value={currentUnit.operations.doorLock} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            Automation system 
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="system" value={currentUnit.operations.system} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            WIFI Password 
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="wifi" value={currentUnit.operations.wifi} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            Appliances 
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="appliances" value={currentUnit.operations.appliances} className="edit-fields" onChange={onChange}/>
          </div>
        </div>

        <div className="flex w-[30rem]">
          <div className="pb-4 w-[40%] flex"> 
            <div className="pb-0.5">
            Appliance brands
            </div>
          </div>
          <div className="pb-4 w-[60%] flex"> 
          <input name="brands" value={currentUnit.operations.brands} className="edit-fields" onChange={onChange}/>
          </div>
        </div>
      </div>
      <div className="flex pr-6 pt-2 justify-end">
        <button className="bg-pass-blue px-7 py-0.5 rounded-full" type="submit">SAVE</button>
      </div>
    </form>
  )
}