import Container from "../components/container";
import { useTrixta } from "../hooks/useTrixta";
import { PageTitle } from "../components/typography";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ReservedTrixtaRoles,
  submitTrixtaActionResponse
} from "@trixtateam/trixta-js-core";
import { TrixtaReactionComponent } from "@trixtateam/trixta-js-rjsf";
import { useEffect } from "react";
import Debug from "../components/debug";
import useRole from "../hooks/useRole";

export default function VerifyPage() {
  const {
    roleName,
    reactions,
    clear,
    setError,
    setResponse,
    errors,
    responses
  } = useRole({
    roleName: ReservedTrixtaRoles.EVERYONE_ANON
  });
  const dispatch = useDispatch();
  const { identity, token, view } = useParams();
  const { auth } = useTrixta();

  console.log("roleName", roleName);

  useEffect(() => {
    if (!identity || !token || !view || !roleName) return;
    //dispatch(updateTrixtaRole({ name: ReservedTrixtaRoles.EVERYONE_ANON }));
    console.log("submitTrixtaActionResponse", roleName);

    dispatch(
      submitTrixtaActionResponse({
        formData: {
          view,
          space: process.env.REACT_APP_TRIXTA_SPACE,
          is_authenticated: auth.isAuthenticated,
          identity,
          token,
          trixta_web_ui_endpoint: view,
          forgot_password_url: "/forgot-password"
        },
        roleName: ReservedTrixtaRoles.EVERYONE_ANON,
        actionName: "auth",
        requestEvent: clear as any,
        responseEvent: setResponse as any,
        errorEvent: setError as any
      })
    );
  }, [
    identity,
    token,
    view,
    roleName,
    dispatch,
    auth.isAuthenticated,
    clear,
    setResponse,
    setError
  ]);

  // if (auth.isAuthorizing)
  //   return (
  //     <div className="text-center">
  //       <Loading />
  //     </div>
  //   );

  useEffect(() => {
    if (!identity || !token) return;
  }, [identity, token]);

  console.log(reactions);

  return (
    <>
      <PageTitle>Verify</PageTitle>
      <Container className="mb-4">
        {responses?.[`${ReservedTrixtaRoles.EVERYONE_ANON}:auth`] && (
          <Debug
            json={responses[`${ReservedTrixtaRoles.EVERYONE_ANON}:auth`]}
          />
        )}
        {errors?.[`${ReservedTrixtaRoles.EVERYONE_ANON}:auth`] && (
          <Debug json={errors[`${ReservedTrixtaRoles.EVERYONE_ANON}:auth`]} />
        )}

        {/* <TrixtaReactionComponent
          roleName="everyone_anon"
          reactionName="agent_invite_onboarding_welcome"
          requestEvent={clear as any}
          responseEvent={setResponse as any}
          errorEvent={setError as any}
        /> */}
        {roleName &&
          Object.keys(reactions).map(key => (
            <div key={key}>
              <p>
                {key}
                {/* <br />
                {roleName} {reactions[key].common.name} */}
              </p>
              <TrixtaReactionComponent
                roleName={roleName}
                reactionName={reactions[key].common.name}
                requestEvent={clear as any}
                responseEvent={setResponse as any}
                errorEvent={setError as any}
              />

              {errors?.[key] && (
                <>
                  Error:
                  <br />
                  <Debug json={errors[key]} />
                </>
              )}

              {responses?.[key] && (
                <>
                  response:
                  <br />
                  <Debug json={responses[key]} />
                </>
              )}
              <hr />
              <hr />
            </div>
          ))}
      </Container>
    </>
  );
}
