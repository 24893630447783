import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import LoginLogo from "../../assets/Login.png";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

library.add(faLock);

export const LoginCard = ({ onSubmit }: any) => {
  const [loginTheme, setLoginTheme] = useState("pass-blue");
  const loginError = useSelector((state: any) => state.login.error);

  console.log(loginError);

  useEffect(() => {
    if (!loginError) {
      setLoginTheme("pass-blue");
    } else {
      setLoginTheme("delete-red");
    }
  }, [loginError]);

  return (
    <form
      className="bg-clarity w-[42rem] h-[22rem] shadow-lg shadow-slate-600"
      onSubmit={onSubmit}
    >
      <div className="flex h-1/3 w-full">
        <div className="flex w-[70%]">
          <div className="w-[69%]">
            <img src={LoginLogo} className="h-1/4 mt-2 ml-2" />
          </div>
          <div
            className={`w-[31%] bg-${loginTheme} transition duration-700 transition duration-700  rounded-bl-[6rem]`}
          ></div>
        </div>

        <div
          className={`flex w-[30%] bg-${loginTheme} transition duration-700`}
        >
          <div className="w-[65%] bg-clarity rounded-tr-[6rem]"></div>
          <div
            className={`w-[35%] bg-${loginTheme} transition duration-700`}
          ></div>
        </div>
      </div>

      <div className="flex h-1/3 w-full">
        <div className="w-[70%] flex flex-col place-content-center pl-4">
          <div className="flex">
            <div className="w-[30%] pl-8 text-edit-grey font-bold text-sm">
              <label>Username</label>
            </div>
            <div className="w-[70%]">
              <input
                className={`login-fields border-${loginTheme} text-${loginTheme} transition duration-700`}
                name="identity"
              ></input>
            </div>
          </div>
          <div className="flex mt-4">
            <div className="w-[30%] pl-8 text-edit-grey font-bold text-sm">
              <label>Password</label>
            </div>
            <div className="w-[70%]">
              <input
                className={`login-fields text-${loginTheme} border-${loginTheme} transition duration-700`}
                type="password"
                name="password"
              ></input>
            </div>
          </div>
        </div>
        <div
          className={`w-[21%] h-full bg-${loginTheme} transition duration-700 flex justify-center items-center pr-2`}
        >
          <FontAwesomeIcon icon="lock" className="text-clarity w-3/4 h-3/4" />
        </div>
        <div
          className={`w-[9%] bg-${loginTheme} transition duration-700`}
        ></div>
      </div>

      <div className="flex h-1/3 w-full">
        <div className="flex w-[70%]">
          <div className="w-[69%]">
            <div className="pl-12 pt-8 h-full">
              <div className="">
                <button
                  className="bg-pass-blue mr-4 w-24 shadow-sm shadow-slate-400 rounded-full h-7 text-sm text-clarity"
                  type="submit"
                >
                  LOGIN
                </button>
              </div>
              <div className="flex text-edit-grey font-bold pt-5 text-[11.5px] items-center">
                <p>FORGOT PASSWORD?</p>
                <p className="pl-4">REGISTER ACCOUNT</p>
              </div>
            </div>
          </div>
          <div
            className={`w-[31%] bg-${loginTheme} transition duration-700 rounded-tl-[6rem]`}
          ></div>
        </div>

        <div
          className={`flex w-[30%] bg-${loginTheme} transition duration-700`}
        >
          <div className="w-[65%] bg-clarity rounded-br-[6rem]"></div>
          <div
            className={`w-[35%] bg-${loginTheme} transition duration-700`}
          ></div>
        </div>
      </div>
    </form>
  );
};
