import { Env } from "../environment/.env.local";

export enum TrixtaManagerActions {
  REQUEST_PROPERTIES = "retrieve_buildings",
  REQUEST_UNITS = "retrieve_units_nopic",
  ADD_CONTENT = "upload_content_auth",
  ADD_PROPERTY = "add_property",
  ADD_UNIT = "add_unit_to_property",
  REMOVE_PROPERTY = "remove_property",
  REMOVE_UNIT = "remove_unit_from_property",
  GRAB_ROLES = "agent_roles_list_storage",
  GET_CONTENT = "retrieve_property_images",
  REQUEST_UNITS_IMAGES = "recieve_unit_images",
  GET_PROFILE = "get_profile",
  UPDATE_PROFILE = "update_profile"
}

export enum TrixtaOwnerActions {
  REQUEST_PROPERTIES = "retrieve_owned_properties",
  REQUEST_UNITS = "retrieve_owned_units",
  ADD_PROPERTY = "add_owned_property",
  ADD_UNIT = "add_owned_unit",
  REMOVE_PROPERTY = "remove_owned_property",
  GET_CONTENT = "recieve_owned_building_images",
  REMOVE_UNIT = "remove_owned_property",
  ADD_CONTENT = "upload_content_auth",
  GET_AGENT_ROLES = "get_agent_roles",
  REQUEST_UNITS_IMAGES = "recieve_owned_unit_images"
}

export enum TrixtaReactions {
  GET_PROPERTIES = "send_properties",
}

export enum TrixtaRoles {
  
}

export enum TrixtaSpace {
  CURRENT = Env.NEXT_PUBLIC_TRIXTA_SPACE
}