import { createSlice } from "@reduxjs/toolkit";
import { Decode } from "../components/content/decode";

export const propertiesSlice = createSlice({
  name: "properties",
  initialState: {
    error: undefined,
    loadProperties: undefined,
    properties: [],
    buildingThumbnails: {},
    buildingImages: {},
    unitImages: {},
    loadedThumbnails: [],
    units: {},
    loading: true,
    loadingUnits: true,
    timeoutBool: false
  },
  reducers: {
    setPropertiesState: (state: any, payload: any) => {
      state.properties = payload?.payload.result;
      state.loading = false;
      state.timeoutBool = true;
    },

    reset: (state: any) => {
      state.properties = [];
      state.buildingThumbnails = {};
      state.buildingImages = {};
      state.unitImages = {};
      state.loadedThumbnails = [];
      state.units = {};
      state.loading = true;
      state.loadingUnits = true;
      state.timeoutBool = false;
      state.loadProperties = undefined;
    },

    setImages: (state: any, payload: any) => {
      state.buildingImages[payload.payload.propertyId] = payload.payload.images;
    },

    setUnitBreakdown: (state: any, payload: any) => {
      for (const id in payload.payload) {
        const propObj = state.properties.find(
          (obj: any) => obj.property_id == id
        ); // Find object in list
        if (propObj) {
          const index = state.properties.findIndex(
            (obj: any) => obj.property_id === id
          );
          console.log(index);
          state.properties[index].units_types = payload.payload[id]; // Update the object
        }
      }
    },

    setLoadedThumbnail: (state: any, payload: any) => {
      state.buildingThumbnails[payload.payload.payload.propertyId] =
        payload.payload.payload.property;
    },

    addThumbnailLoadedState: (state: any, payload: any) => {
      state.loadedThumbnails = [...state.loadedThumbnails, payload.payload];
      state.buildingImages[payload.payload.property_id] = [];
    },

    addEmptyImagesBuilding: (state: any, payload: any) => {
      if (!state.buildingThumbnails[payload.payload.property_id]) {
        state.buildingThumbnails[payload.payload.property_id] = [];
      }

      if (!state.buildingImages[payload.payload.property_id]) {
        state.buildingImages[payload.payload.property_id] = [];
      }
    },

    addEmptyImagesUnit: (state: any, payload: any) => {
      console.log("adding empty");
      if (!state.unitImages[payload.payload.property_id]) {
        state.unitImages[payload.payload.property_id] = {};
      }
      if (
        !state.unitImages[payload.payload.property_id][payload.payload.unit_id]
      ) {
        state.unitImages[payload.payload.property_id][payload.payload.unit_id] =
          [];
      }
    },

    pushThumbnailStart: (state: any, payload: any) => {
      if (!state.buildingImages[payload.payload.property_id]) {
        state.buildingImages[payload.payload.property_id] = [];
      } else {
        state.buildingImages[payload.payload.property_id] =
          state.buildingImages[payload.payload.property_id].filter(
            (subArray: any) => subArray[0] !== "thumbnail"
          );
      }
      state.buildingThumbnails[payload.payload.property_id] =
        payload.payload.image;
      state.buildingImages[payload.payload.property_id].unshift([
        "thumbnail",
        payload.payload.image
      ]);
    },

    pushUnitThumbnailStart: (state: any, payload: any) => {
      if (!state.unitImages[payload.payload.property_id]) {
        state.unitImages[payload.payload.property_id] = {};
      }
      if (
        !state.unitImages[payload.payload.property_id][payload.payload.unit_id]
      ) {
        state.unitImages[payload.payload.property_id][payload.payload.unit_id] =
          [];
      } else {
        state.unitImages[payload.payload.property_id][payload.payload.unit_id] =
          state.unitImages[payload.payload.property_id][
            payload.payload.unit_id
          ].filter((subArray: any) => Decode(subArray[0]) !== "thumbnail");
      }
      state.unitImages[payload.payload.property_id][
        payload.payload.unit_id
      ].unshift(["thumbnail", payload.payload.image]);
    },

    addTempImage: (state: any, payload: any) => {
      let property;
      if (payload.payload.propertyType == "property") {
        if (!state.buildingImages[payload.payload.propertyId]) {
          state.buildingImages[payload.payload.propertyId] = [];
        }
        state.buildingImages[payload.payload.propertyId] = [
          ...state.buildingImages[payload.payload.propertyId],
          [payload.payload.imageName, payload.payload.base64Image]
        ];
        property = state.properties.find(
          (obj: any) => obj.property_id === payload.payload.propertyId
        );
      } else {
        if (!state.unitImages[payload.payload.propertyId]) {
          state.unitImages[payload.payload.propertyId] = {};
        }
        if (
          !state.unitImages[payload.payload.propertyId][
            payload.payload.unitNumber
          ]
        ) {
          state.unitImages[payload.payload.propertyId][
            payload.payload.unitNumber
          ] = [];
        }
        state.unitImages[payload.payload.propertyId][
          payload.payload.unitNumber
        ] = [
          ...state.unitImages[payload.payload.propertyId][
            payload.payload.unitNumber
          ],
          [payload.payload.imageName, payload.payload.base64Image]
        ];

        const index = state.units[payload.payload.propertyId].findIndex(
          (item: any) => item.property_id === payload.payload.propertyId
        );
        property = state.units[payload.payload.propertyId][index];
      }
    },

    setUnitState: (state: any, payload: any) => {
      console.log(payload?.payload);
      state.units = {
        ...state.units,
        [payload?.payload.property_id]: payload?.payload.units
          ? payload?.payload.units
          : []
      };

      state.loadingUnits = false;
    },

    setUnitImages: (state: any, { payload }: any) => {
      const { propertyId, unitId, images } = payload;

      if (!state.unitImages[propertyId]) {
        state.unitImages[propertyId] = {};
      }

      state.unitImages[propertyId][unitId] = images;
    },

    addPropertyState: (state: any, payload: any) => {
      const tempProperty = payload?.payload.formData;
      console.log(tempProperty);
      state.properties = [...state.properties, tempProperty];
    },

    addUnitState: (state: any, payload: any) => {
      const tempProperty = payload?.payload.formData;

      console.log("Adding");

      if (!state.units[tempProperty.property_id]) {
        state.units[tempProperty.property_id] = [tempProperty];
      } else {
        console.log("puahing");
        state.units[tempProperty.property_id].push(tempProperty);
      }
    },
    startLoader: (state: any) => {
      state.loading = true;
    },
    startUnitLoader: (state: any) => {
      state.loadingUnits = true;
    },
    editPropertyState: (state: any, payload: any) => {
      const tempProperty = payload?.payload.formData;
      const index = state.properties.findIndex(
        (obj: any) => obj.property_id === tempProperty.property_id
      );

      if (index >= 0) {
        tempProperty.images = state.properties[index].images;
        state.properties.splice(index, 1, tempProperty);
      } else {
        state.properties = [...state.properties, tempProperty];
      }
    },
    editUnitState: (state: any, payload: any) => {
      const tempUnit = payload?.payload.formData;
      const unitsOfProperty = state.units[tempUnit.property_id];

      const index = unitsOfProperty.findIndex(
        (obj: any) => obj.unit_id === tempUnit.unit_id
      );

      if (index >= 0) {
        unitsOfProperty.splice(index, 1, tempUnit);
      } else {
        unitsOfProperty.push(tempUnit);
      }
    },
    removePropertyState: (state: any, payload: any) => {
      state.properties = state.properties.filter(
        (property: any) =>
          property.property_id !== payload?.payload.formData.property_id
      );
    },
    removeUnitState: (
      state: any,
      { payload: { formData: unit_details } }: any
    ) => {
      state.units[unit_details.property_id] = state.units[
        unit_details.property_id
      ].filter((unit: any) => unit.unit_id !== unit_details.unit_number);

      delete state.unitImages[unit_details.property_id][
        unit_details.unit_number
      ];
    },
    catchTimeout: (state: any) => {
      state.timeoutBool = false;
    },
    catchError: (state: any, payload: any) => {
      console.log("error");
      console.log(payload);
    },

    // Store properties temporarily before filtering; if not manager/admin
    setOwnedPropertiesState: (state: any, payload: any) => {
      state.loadProperties = payload?.payload.result;
    },

    // filter stored properties based on ownership
    filterProperties: (state: any, payload: any) => {
      state.properties = state.loadProperties.filter((property: any) => {
        if (property.owner === payload.payload) return true;

        for (const [key, value] of Object.entries(property.units)) {
          if (value === payload.payload) {
            return true;
          }
        }

        return false;
      });
      state.loading = false;
      state.timeoutBool = true;
    }
  }
});

export const {
  addTempImage,
  setPropertiesState,
  setOwnedPropertiesState,
  addPropertyState,
  setUnitState,
  setUnitBreakdown,
  addUnitState,
  editUnitState,
  removePropertyState,
  removeUnitState,
  pushThumbnailStart,
  pushUnitThumbnailStart,
  startLoader,
  startUnitLoader,
  editPropertyState,
  catchTimeout,
  catchError,
  filterProperties,
  setImages,
  setUnitImages,
  setLoadedThumbnail,
  addThumbnailLoadedState,
  reset,
  addEmptyImagesBuilding,
  addEmptyImagesUnit
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
