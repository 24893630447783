import React from "react";

import Container from "../components/container";
import { useTrixta } from "../hooks/useTrixta";
import { PageTitle } from "../components/typography";
import { useSelector } from "react-redux";
import Alert from "../components/alert";
import { useNavigate } from "react-router-dom";
import { LoginCard } from "../components/login/loginCard";
import BackgroundImage from "../assets/Background 2.png";

const LoginPage = () => {
  const error = useSelector((state: any) => state.login.error);
  const { auth, LoginUser } = useTrixta();
  const navigate = useNavigate();

  if (auth?.isAuthenticated) {
    navigate("/");
  }

  const onSubmit = (e: any) => {
    e.preventDefault();

    const loginObj = {
      identity: e.target.identity.value,
      password: e.target.password.value
    };

    LoginUser(loginObj);
  };

  console.log(auth);

  return (
    <>
      <div
        className={`flex justify-center items-center bg-custom-image h-screen w-screen bg-cover bg-no-repeat bg-center`}
      >
        {!auth?.isAuthenticated && (
          // <TrixtaActionComponent
          //   roleName={ReservedTrixtaRoles.EVERYONE_ANON}
          //   actionName="login"
          //   requestEvent={clearError as any}
          //   responseEvent={setUser as any}
          //   errorEvent={setError as any}
          // />
          <LoginCard onSubmit={onSubmit} />
        )}
      </div>
    </>
  );
};

export default LoginPage;
