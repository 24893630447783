import { PageTitle } from "../components/typography";
import { Breadcrumbs } from "../components/breadcrumbs";
import TestData from "../components/TESTDATA/TestData";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();

  function generateInspect() {
    return (
      <button
        className="text-edit-grey min-h-[1.55rem] max-w-[1.55rem] font-bold flex flex-row hover:opacity-60"
        onClick={() => navigate("/services/event")}
      >
        <img
          src="https://i.imgur.com/EgBbgcR.png"
          width="24rem"
          height="24rem"
          className="min-h-[1.55rem] max-w-[1.55rem] mr-2"
        ></img>
      </button>
    );
  }

  return (
    <>
      <div className="container mx-auto p-6 h-[52rem]">
        <Breadcrumbs />

        <PageTitle className="mb-[8rem]">Service History</PageTitle>
        <div className=" flex flex-row justify-end absolute gap-[1rem] top-[3.5rem] left-[70%] translate-x-[10%]">
          <div className="border-2 bg-search-grey rounded-2xl h-[2rem] w-[14rem] p-[1rem] z-50">
            <button>
              <img
                className="mt-[-1.5rem]"
                src="https://i.imgur.com/odh2qbV.png"
                width="14rem"
                height="14rem"
              ></img>
            </button>
            <textarea
              placeholder="Search"
              className="bg-search-grey absolute bottom-[0.2rem] ml-3 resize-none outline-none h-[1.6rem] w-[11rem] "
            ></textarea>
          </div>
        </div>

        <div className="flex flex-row mt-[-6rem] mb-[2rem]">
          <div className="flex flex-col gap-[1.7rem]">
            <h3 className="text-lg text-smarthost-navy text-left font-bold p-4 pl-6 pr-6 hover:bg-gray-100 mr-6 right-[1.5rem]">
              Date
            </h3>
            {TestData.date.map((data, index) => (
              <div className="text-edit-grey font-bold" key={index}>
                {data}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center gap-[1.7rem]">
            <h3 className="text-lg text-smarthost-navy font-bold p-4 pl-6 pr-6 hover:bg-gray-100 whitespace-nowrap">
              Service ID
            </h3>
            {TestData.serviceID.map((data, index) => (
              <div className="text-smarthost-navy font-bold" key={index}>
                {data}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center gap-[1.7rem]">
            <h3 className="text-lg text-smarthost-navy font-bold p-4 pl-6 pr-6 hover:bg-gray-100 whitespace-nowrap">
              Service Type
            </h3>
            {TestData.serviceType.map((data, index) => (
              <div className="text-edit-grey font-bold" key={index}>
                {data}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center gap-[1.7rem]">
            <h3 className="text-lg text-smarthost-navy font-bold p-4 pl-6 pr-6 hover:bg-gray-100">
              Property
            </h3>
            {TestData.property.map((data, index) => (
              <div className="text-smarthost-navy font-bold" key={index}>
                {data}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center gap-[1.7rem]">
            <h3 className="text-lg text-smarthost-navy font-bold p-4 pl-6 pr-6 hover:bg-gray-100 ">
              Cost
            </h3>
            {TestData.cost.map((data, index) => (
              <div className="text-edit-grey font-bold" key={index}>
                {data}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center mr-4 ml-4">
            <h3 className="text-lg text-smarthost-navy font-bold p-4 pl-6 pr-6 hover:bg-gray-100">
              Status
            </h3>
            <div className="mt-[1.3rem]">
              {TestData.status.map((data, index) => (
                <div
                  className={` h-[3.1rem]  text-edit-grey text-sm font-bold p-[0.8rem] w-[8rem] text-white text-center ${
                    data === "Pending"
                      ? "bg-focus-blue"
                      : data === "Resolved"
                      ? "bg-smarthost-cyan"
                      : "bg-smarthost-coral"
                  }`}
                  key={index}
                >
                  {data.toUpperCase()}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-[1.63rem] mt-[5.3rem]">
            {TestData.date.map(index => (
              <div className=" right-[1rem] top-[0.6rem]" key={index}>
                {generateInspect()}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-start text-focus-blue font-bold ">
          <div className="flex py-6">
            <div className="pr-4 text-gray-500">1</div>
            <div className="pr-4 text-gray-400">2</div>
            <div className="pr-4 text-gray-300">3</div>
            <div className="pr-4 text-gray-200">4</div>
            <div className="pr-4 text-gray-200">5</div>
            <div className="pr-6 text-gray-200">...</div>

            <div className="pr-4 font-bold text-focus-blue">next</div>
            <div className="text-gray-400">return</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
