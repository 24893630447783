import React, { useEffect, useState } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from "chart.js";
import { Line } from "react-chartjs-2";

export const PropertyValueChart = ({ propertyValue, dateRange }: any) => {
  const monthsTemplate = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
  ];

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  let monthMonthsTemplate: any;
  let monthLabels;

  let tempData: any[] = [];
  const [currentData, setCurrentData] = useState(propertyValue);
  const [monthPart, setMonthPart] = useState("first");

  if (dateRange === "month") {
    if (monthPart === "first") {
      monthMonthsTemplate = monthsTemplate.slice(0, 6);
      monthLabels = labels.slice(0, 6);
    } else {
      monthMonthsTemplate = monthsTemplate.slice(6);
      monthLabels = labels.slice(6);
    }
  }

  const calcCurrentData = () => {
    tempData = [];
    let iterTemplate;

    {
      dateRange === "year"
        ? (iterTemplate = monthsTemplate)
        : (iterTemplate = monthMonthsTemplate);
    }

    console.log(iterTemplate);

    if (!propertyValue) {
      return tempData;
    }
    iterTemplate.forEach((element: any) => {
      if (!propertyValue[element]) {
        tempData.push(null);
      } else {
        tempData.push(propertyValue[element]);
      }
    });
    console.log(tempData);
    return tempData;
  };

  useEffect(() => {
    setCurrentData(calcCurrentData());
  }, [propertyValue, dateRange, monthPart]);

  console.log(currentData);

  Chart.register(CategoryScale, LinearScale, PointElement, LineElement);
  const data = {
    labels: dateRange === "year" ? labels : monthLabels,
    datasets: [
      {
        label: "# of Votes",
        data: currentData,
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)"
      }
    ]
  };

  const options = {
    responsive: true,
    aspectRatio: 1.55,
    scales: {
      y: {
        grid: {
          display: false
        }
      },
      x: {
        ticks: {
          color: "#122650"
        },
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <div>
      <div className="text-lg flex pb-2 justify-center">
        <button
          className="bg-gray-100 px-[9px] pb-[1px] font-bold rounded-full text-gray-200 hover:text-gray-500"
          onClick={() => {
            setMonthPart("first");
          }}
        >
          {"<"}
        </button>

        <p className="px-4 text-focus-blue font-bold">
          {dateRange === "year" ? "1/1" : monthPart === "first" ? "1/2" : "2/2"}
        </p>

        <button
          className="bg-gray-100 px-[9px] pb-[1px] font-bold rounded-full text-gray-200 hover:text-gray-500"
          onClick={() => {
            setMonthPart("second");
          }}
        >
          {">"}
        </button>
      </div>
      <div className="line-chart">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};
