import { submitTrixtaActionResponse } from "@trixtateam/trixta-js-core";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  AddPropertyFormData,
  RemovePropertyFormData
} from "../components/propertyList/interfaces";
import {
  TrixtaManagerActions,
  TrixtaOwnerActions
} from "../constants/trixtaConstants";
import {
  startUnitLoader,
  catchTimeout,
  addUnitState,
  setUnitState,
  editUnitState,
  removeUnitState,
  catchError,
  setUnitImages,
  addEmptyImagesUnit
  //pushUnitThumbnailStart
} from "../reducers/propertySlice";

const useUnitsHook = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.app.user);
  let userRole: string;
  let trixtaActions: any;

  if (user && user.trixtaMeta && user.trixtaMeta.roles) {
    if (user.trixtaMeta.roles.includes("property_manager")) {
      userRole = "property_manager";
      trixtaActions = TrixtaManagerActions;
    } else {
      userRole = "property_owner";
      trixtaActions = TrixtaOwnerActions;
    }
  }

  const setUnitLoading = (): void => {
    dispatch(startUnitLoader());
  };

  const getUnits = (formData: any) => {
    try {
      console.log("getting");
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.REQUEST_UNITS,
          responseEvent: setUnitState as any,
          timeoutEvent: catchTimeout as any,
          errorEvent: catchError as any,
          formData: formData,
          timeout: 60000
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getUnitImages = (formData: any) => {
    console.log("REQUESTING UNIT IMAGES");
    try {
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.REQUEST_UNITS_IMAGES,
          formData: formData,
          timeout: 60000
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // const pushUnitThumbnail = (payload: any): void => {
  //   dispatch(pushUnitThumbnailStart(payload))
  // }

  const addUnit = useCallback(
    (formData: any) => {
      dispatch(addEmptyImagesUnit(formData));
      console.log(formData);
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.ADD_UNIT,
          requestEvent: addUnitState as any,
          errorEvent: catchError as any,
          formData: formData,
          timeout: 60000
        })
      );
    },
    [dispatch]
  );

  const editUnit = useCallback(
    (formData: AddPropertyFormData) => {
      let sendFormData = formData;
      sendFormData = { ...sendFormData, new_template: false };
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.ADD_UNIT,
          requestEvent: editUnitState as any,
          errorEvent: catchError as any,
          formData: sendFormData,
          timeout: 60000
        })
      );
    },
    [dispatch]
  );

  const removeUnit = useCallback(
    (formData: RemovePropertyFormData) => {
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.REMOVE_UNIT,
          requestEvent: removeUnitState as any,
          errorEvent: catchError as any,
          formData: formData,
          timeout: 60000
        })
      );
    },
    [dispatch]
  );

  return {
    removeUnit,
    setUnitLoading,
    editUnit,
    addUnit,
    getUnits,
    getUnitImages
  };
};

export default useUnitsHook;
