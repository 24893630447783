import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    error: null
  },
  reducers: {
    setError: (state: any, payload: any) => {
      state.error = payload.payload ? payload.payload : payload;
      console.log(payload);
    },
    clearError: (state: any) => {
      state.error = null;
      console.log("no resp?");
    }
  }
});

export const { setError, clearError } = loginSlice.actions;

export default loginSlice.reducer;
