import Loader from "../../assets/Loader.gif"

const Loading = () => {
  return (
    <div className="flex justify-center">
      <div className=
        'mt-16 w-14 h-14'
      >
        <img src={Loader} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loading;
