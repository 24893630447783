import { listenForTrixtaReactionResponse, ReservedTrixtaRoles } from "@trixtateam/trixta-js-core";
import { put, takeEvery } from 'redux-saga/effects';
import { setImages, setUnitImages } from "../reducers/propertySlice";


// indicate that the action is a PayloadAction with the payload of ReactionPayload type
function* checkReactionSaga({ payload, meta }: any) {
  const { initial_data } = payload.reactionDetails;
  const { reactionName } = meta;

  if(reactionName === 'send_building_images'){
    yield put(setImages(initial_data));
  } else if (reactionName === 'send_unit_images') {
    yield put(setUnitImages(initial_data));
  }
}

export function* contentSaga() {
  yield takeEvery<any>(listenForTrixtaReactionResponse({
    roleName: ReservedTrixtaRoles.EVERYONE_AUTHED,
    reactionName: "send_building_images"
  }), checkReactionSaga);
}

export function* unitContentSaga() {
  yield takeEvery<any>(listenForTrixtaReactionResponse({
    roleName: ReservedTrixtaRoles.EVERYONE_AUTHED,
    reactionName: "send_unit_images"
  }), checkReactionSaga);
}
