import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateTrixtaRole } from "@trixtateam/trixta-js-core";
import { TrixtaActionComponent } from "@trixtateam/trixta-js-rjsf";
import { selectActionsByRole } from "../../reducers/trixta";
import { setError, setResponse, clear } from "../../reducers/roleSlice";
import Debug from "../debug";
import Alert from "../alert";

const Role = ({ roleName }: { roleName: string }) => {
  const error = useSelector((state: any) => state.role.error);
  const response = useSelector((state: any) => state.role.response);
  const reactions = useSelector(
    (state: any) => selectActionsByRole(state, roleName),
    shallowEqual
  );
  const actions = useSelector(
    (state: any) => selectActionsByRole(state, roleName),
    shallowEqual
  );
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [selectedReaction, setSelectedReaction] = useState<string | null>(null);

  const dispatch = useDispatch();

  console.log("Reactions", reactions);
  console.log("Actions", actions);

  useEffect(() => {
    setSelectedAction(null);
    setSelectedReaction(null);
    console.log(roleName)
    dispatch(updateTrixtaRole({ name: roleName }));
  }, [dispatch, roleName]);
  return (
    <>
      <div>
        Actions
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 p-2">
            <select
              className="w-full mb-4"
              value={selectedAction || ""}
              onChange={e => setSelectedAction(e.target.value)}
            >
              {Object.keys(actions).map(key => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
            <Debug json={actions[selectedAction as string]} />
          </div>
          <div className="flex flex-col w-1/3 p-2">
            {roleName && selectedAction && (
              <TrixtaActionComponent
                actionName={actions[selectedAction as string]?.common?.name}
                roleName={roleName}
                requestEvent={clear as any}
                responseEvent={setResponse as any}
                errorEvent={setError as any}
              />
            )}
          </div>
          <div className="flex flex-col w-1/3 p-2">
            {error && (
              <Alert
                type="error"
                title={error.error?.message || "An error occurred"}
              />
            )}
            {error && <Debug json={error} />}
            {response && <Debug json={response} />}
          </div>
        </div>
      </div>
      <div>
        Reactions
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 p-2">
            <select
              className="w-full mb-4"
              value={selectedReaction || ""}
              onChange={e => setSelectedReaction(e.target.value)}
            >
              {Object.keys(reactions).map(key => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
            <Debug json={reactions[selectedReaction as string]} />
          </div>
          <div className="flex flex-col w-1/3 p-2">
            {roleName && selectedReaction && (
              <TrixtaActionComponent
                actionName={actions[selectedReaction as string]?.common?.name}
                roleName={roleName}
                requestEvent={clear as any}
                responseEvent={setResponse as any}
                errorEvent={setError as any}
              />
            )}
          </div>
          <div className="flex flex-col w-1/3 p-2">
            {error && (
              <Alert
                type="error"
                title={error.error?.message || "An error occurred"}
              />
            )}
            {error && <Debug json={error} />}
            {response && <Debug json={response} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
