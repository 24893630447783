import { useEffect } from "react";
import {
  LOGIN_TRIXTA_SUCCESS,
  signoutTrixta,
  useTrixtaAuth,
  submitTrixtaActionResponse,
  ReservedTrixtaRoles
} from "@trixtateam/trixta-js-core";
import { TrixtaOwnerActions } from "../constants/trixtaConstants";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "usehooks-ts";
import { setUserRole, catchError } from "../reducers/appSlice";
import { reset } from "../reducers/propertySlice";

import { setUser } from "../reducers/appSlice";
import { setError, clearError } from "../reducers/loginSlice";

export const useTrixta = (): {
  auth: any;
  logout: () => void;
  agent: any;
  getRoles: any;
  LoginUser: any;
  uploadPfp: any;
} => {
  const dispatch = useDispatch();
  const auth = useTrixtaAuth();
  const { user } = useSelector((state: any) => state.app);
  const [agent, setAgent] = useLocalStorage<any>(
    `${process.env.REACT_APP_TRIXTA_SPACE as string}_agent`,
    undefined
  );

  useEffect(() => {
    if (user) {
      setAgent(user);

      dispatch({
        type: LOGIN_TRIXTA_SUCCESS,
        payload: user
      });
    }
  }, [dispatch, setAgent, user]);

  const logout = () => {
    console.log("Logout triggered");
    setAgent(null);
    dispatch(signoutTrixta());
    dispatch(reset());
  };

  const uploadPfp = (formData: any) => {
    console.log(formData);
    dispatch(
      submitTrixtaActionResponse({
        roleName: "property_manager",
        actionName: "upload_pfp",
        requestEvent: clearError as any,
        errorEvent: setError as any,
        formData: formData,
        timeout: 60000
      })
    );
  };

  const LoginUser = (formData: any) => {
    console.log(formData);
    dispatch(
      submitTrixtaActionResponse({
        roleName: ReservedTrixtaRoles.EVERYONE_ANON,
        actionName: "login",
        requestEvent: clearError as any,
        responseEvent: setUser as any,
        errorEvent: setError as any,
        formData: formData,
        timeout: 60000
      })
    );
  };

  const getRoles = (formData: any): void => {
    dispatch(
      submitTrixtaActionResponse({
        roleName: "property_manager",
        actionName: TrixtaOwnerActions.GET_AGENT_ROLES,
        responseEvent: setUserRole as any,
        errorEvent: catchError as any,
        formData: formData,
        timeout: 60000
      })
    );

    dispatch(
      submitTrixtaActionResponse({
        roleName: "property_owner",
        actionName: TrixtaOwnerActions.GET_AGENT_ROLES,
        responseEvent: setUserRole as any,
        errorEvent: catchError as any,
        formData: formData,
        timeout: 60000
      })
    );
  };

  return { auth, logout, agent, getRoles, LoginUser, uploadPfp };
};
