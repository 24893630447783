import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { PropertyBase } from "./interfaces";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { PreLoad } from "../content/preLoadImg";

const PropertyList = ({ properties, onEditClick, addBuilding }: any) => {
  const stateThumbnails = useSelector(
    (state: any) => state.properties.buildingThumbnails
  );
  const [thumbnails, setThumbnails] = useState<any>({});

  useEffect(() => {
    if (!stateThumbnails) {
      return;
    }
    PreLoad(stateThumbnails, setThumbnails, thumbnails);
  }, [stateThumbnails]);

  return (
    <div className="flex flex-wrap mr-3">
      <>
        {properties.map((property: PropertyBase) => {
          const { name, property_id } = property;
          return (
            <div
              key={property_id}
              className="
                    mr-6 
                    mb-6 
                    flex 
                    flex-col 
                    justify-center 
                    items-center 
                    rounded-xl"
            >
              <div
                className="
                      pb-2 w-48 
                      text-field-grey 
                      text-sm 
                      font-semibold 
                      truncate 
                      text-center"
              >
                {name ? <>{name}</> : <>_</>}
              </div>
              <>
                {thumbnails[property_id] ? (
                  <img
                    src={thumbnails[property_id]}
                    alt={`Missing`}
                    loading="lazy"
                    className="
                          object-cover 
                          rounded-xl 
                          w-56 h-56 
                          hover:opacity-75
                          hover:shadow-md"
                    onClick={() => onEditClick(property_id)}
                  />
                ) : stateThumbnails[property_id] === "empty" ? (
                  <div
                    className="bg-hover-grey rounded-xl w-56 h-56 flex items-center justify-center"
                    onClick={() => onEditClick(property_id)}
                  ></div>
                ) : (
                  <div className="bg-hover-grey rounded-xl w-56 h-56 flex items-center justify-center">
                    <div className="spinner">
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                    </div>
                  </div>
                )}
              </>
            </div>
          );
        })}
        <div
          className="
              w-56
              h-56
              mt-7
              bg-nav-grey
              rounded-xl
              flex 
              items-center 
              justify-center
              border-2 border-slate-100 border-solid
              hover:opacity-75
              hover:shadow-sm"
          onClick={() => {
            addBuilding();
          }}
        >
          <PlusCircleIcon className="text-slate-400 w-1/4 h-1/4" />
        </div>
      </>
    </div>
  );
};

export default PropertyList;
