import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { PropertyBase } from "../components/propertyList/interfaces";
import Loading from "../components/loading";
import UnitList from "../components/propertyList/unitList";
import useUnitsHook from "../hooks/useUnits";
import usePropertiesHook from "../hooks/useProperties";

import { loadImages } from "../components/content/loadImages";
import { Decode } from "../components/content/decode";

import { addBuildingFields } from "../components/formTemplates/buildingTemplates";

import { DetailsPageHeader } from "../components/formViewComponants/detailsPageHeader";
import { DetailsCardView } from "../components/formViewComponants/detailsCardView";
import { Tags } from "../components/formViewComponants/tags";
import { AdditionalImages } from "../components/formViewComponants/additionalImages";
import { DescriptionAndFeatures } from "../components/formViewComponants/descriptionAndFeatures";
import { EditDetailsCard } from "../components/formEditComponants/editDetailsCard";
import { RemoveTemplateProperties } from "../components/propertyList/removeTemplateBuildings";
import { DeletePropertyButton } from "../components/formViewComponants/deletePropertyButton";

const BuildingDetails = () => {
  const disabled = useRef(true);
  const properties = useSelector((state: any) => state.properties);
  const buildingsList = useSelector(
    (state: any) => state.properties.properties
  );
  const user = useSelector((state: any) => state.app.user);
  const navigate = useNavigate();

  const { removeBuilding, updateBuildings } = usePropertiesHook();

  const { getUnits, setUnitLoading, getUnitImages, removeUnit } =
    useUnitsHook();

  const [isEditingDetailsCard, setIsEditingDetailsCard] =
    useState<boolean>(false);
  const [propertyObj, setPropertyObj] = useState<undefined | PropertyBase>(
    undefined
  );
  const [mainImg, setMainImg] = useState<any>(undefined);

  const [mainImgLoaded, setMainImgLoaded] = useState<boolean>(false);
  const [calledUnits, setCalledUnits] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<boolean>(false);

  const [unitsList, setUnitsList] = useState<undefined | any[]>(undefined);

  const { slug: id } = useParams();

  let propertyId: any = propertyObj?.property_id;

  useEffect(() => {
    if (!id) {
      return;
    }

    let foundPropertyObj;

    if (id.startsWith("new_") && !propertyObj) {
      setIsEditingDetailsCard(true);
      foundPropertyObj = buildingsList.find(
        (obj: any) => obj.property_id === id.slice(4)
      );
      console.log(buildingsList);
    } else {
      foundPropertyObj = buildingsList.find(
        (obj: any) => obj.property_id === id
      );
    }

    if (foundPropertyObj) {
      setPropertyObj(foundPropertyObj);
      propertyId = foundPropertyObj.property_id; // if propertyId needs to be used within the same useEffect.
    }
  }, [buildingsList, id]);

  useEffect(() => {
    if (!user || !user.trixtaMeta || !user.trixtaMeta.roles) {
      return;
    }

    if (
      user.trixtaMeta.roles.includes("property_manager") ||
      user.agent_id === propertyObj?.owner
    ) {
      disabled.current = false;
    } else {
      disabled.current = true;
    }
  }, [user]);

  useEffect(() => {
    if (!properties.units[propertyId]) {
      setUnitLoading();
    }
  }, []);

  useEffect(() => {
    if (filtered || !unitsList) {
      return;
    }
    if (
      propertyObj &&
      properties.units[propertyId] &&
      unitsList.length > 0 &&
      !properties.loadingUnits
    ) {
      RemoveTemplateProperties(
        properties.units[propertyId],
        deleteUnit,
        setUnitsList
      );
      setFiltered(true);
    }
  }, [unitsList]);

  const deleteUnit = (unit: any) => {
    removeUnit({ property_id: unit.property_id, unit_number: unit.unit_id });
  };

  // Setting units when loaded
  useEffect(() => {
    if (!user || !user.trixtaMeta || !user.trixtaMeta.roles) {
      return;
    }

    console.log(properties.units[propertyId]);

    if (propertyObj) {
      if (properties.loadingUnits) {
        return;
      }

      if (properties.units[propertyId] && !unitsList) {
        console.log("Setting units");
        console.log(properties.units[propertyId]);
        setUnitsList(properties.units[propertyId]);
      }
    }
  }, [properties.units, propertyObj]);

  useEffect(() => {
    if (
      properties.loadingUnits &&
      propertyObj &&
      !calledUnits &&
      !properties.units[propertyId]
    ) {
      setCalledUnits(true);
      getUnits({ property_id: propertyObj?.property_id });
      if (!properties.unitImages[propertyObj.property_id]) {
        getUnitImages({ property_id: propertyObj?.property_id });
      }
    }

    if (mainImgLoaded) {
      return;
    }

    if (propertyObj && properties.buildingImages[propertyObj.property_id]) {
      if (properties.buildingImages[propertyObj.property_id].length === 0) {
        setMainImg("empty");
        setMainImgLoaded(true);
        return;
      }

      if (
        Decode(properties.buildingImages[propertyObj.property_id][0][0]) !==
        "thumbnail"
      ) {
        setMainImgLoaded(true);
        return;
      }

      const currentImg: any = [
        properties.buildingImages[propertyObj.property_id][0]
      ];
      setMainImg(currentImg);
      setMainImgLoaded(true);
      loadImages(currentImg, setMainImg);
    }
  }, [propertyObj, properties.buildingImages]);

  const RemoveBuilding = () => {
    console.log("handling delete");
    removeBuilding({ property_id: propertyObj?.property_id });
    navigate("/");
  };

  const handleSubmit = () => {
    console.log("are we submitting?");
    if (propertyObj) {
      setPropertyObj({ ...propertyObj, new_template: false });
      updateBuildings(propertyObj, "edit");
    }
  };

  return (
    <>
      {!user || !user.trixtaMeta || !user.trixtaMeta.roles || !propertyObj ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <DetailsPageHeader
            headerTitle={propertyObj.name ? propertyObj.name : "No Name"}
          />

          {isEditingDetailsCard ? (
            <EditDetailsCard
              setEdit={setIsEditingDetailsCard}
              setPropertyObj={setPropertyObj}
              propertyObj={propertyObj}
              template={addBuildingFields}
              propertyType="building"
              setMainImg={setMainImg}
              mainImg={mainImg}
              onSubmit={handleSubmit}
            />
          ) : (
            <DetailsCardView
              building={propertyObj}
              propertyType="building"
              setEdit={setIsEditingDetailsCard}
              mainImg={mainImg}
            />
          )}

          <Tags
            tags={propertyObj.tags}
            propertyObj={propertyObj}
            setPropertyObj={setPropertyObj}
            propertyType={"building"}
          />

          <AdditionalImages
            images={properties.buildingImages[propertyObj.property_id]}
            property_id={propertyId}
            setPropertyObj={setPropertyObj}
            propertyObj={propertyObj}
          />

          <DescriptionAndFeatures
            propertyObj={propertyObj}
            setPropertyObj={setPropertyObj}
          />

          <UnitList
            property_id={propertyId}
            loadingUnits={properties.loadingUnits}
            currentUnits={unitsList}
            unitImages={properties.unitImages[propertyObj.property_id]}
            building={propertyObj}
          />

          <DeletePropertyButton removePropertyFunction={RemoveBuilding} />
        </div>
      )}
    </>
  );
};

export default BuildingDetails;
