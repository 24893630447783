export const addBuildingFields: any = {
  owner: {title: 'Owner', required: false, disabled: false},
  type: {title: 'Type', required: false, disabled: false},
  address: {title: 'Address', required: false, disabled: false},
}

export const viewBuildingFields: any = {
  owner: {title: 'Owner'},
  type: {title: 'Type'},
  address: {title: 'Address'},
}

export const addBuildingFieldsTextArea: any = {
  description: {title: 'Description', required: false},
  features: {title: 'Features', required: false}
} 

// full property form
export const submitFormTemplate = {
  name: "",
  address: '',
  type: '',
  area: '',
  bathrooms: '',
  images: [],
  bedrooms: '',
  tags: [],
  units: {},
  specifications: {
    description: '',
    features: ''
  },
  tenant: {
    tenantName: '',
    tenantEmail: '',
    startDate: '',
    endDate: ''
  },
  property_id: '',
  owner: '',
  new_template: true,
  space: 'smarthost.space.trixta.io'
}
