import { useState } from "react";

export const AddEditContactPerson = ({
  setEdit,
  updateContactPersons,
  currentContact,
  contactPerson
}: any) => {
  const [person, setPerson] = useState(contactPerson);

  const onContactSubmit = (e: any) => {
    updateContactPersons("edit", currentContact, person);
    setEdit(false);
  };

  return (
    <form onSubmit={onContactSubmit} className="w-full">
      <div className="text-semi-focus-blue pr-2 pb-6 text-sm font-semibold">
        Edit Contant Person
      </div>
      <div className="w-[50%]">
        <div className="flex">
          <div className="w-[50%] text-field-grey font-semibold text-sm">
            Name
          </div>
          <input
            value={person.contactName}
            name="contactName"
            onChange={(e: any) =>
              setPerson({ ...person, contactName: e.target.value })
            }
            className="w-[75%] edit-fields"
          />
        </div>

        <div className="flex pt-3">
          <div className="w-[50%] text-field-grey font-semibold text-sm">
            Surname
          </div>
          <input
            value={person.contactSurname}
            name="contactSurname"
            onChange={(e: any) =>
              setPerson({ ...person, contactSurname: e.target.value })
            }
            className="w-[75%] edit-fields"
          />
        </div>

        <div className="flex pt-9">
          <div className="w-[50%] text-field-grey font-semibold text-sm">
            Email
          </div>
          <input
            value={person.contactEmail}
            name="contactEmail"
            onChange={(e: any) =>
              setPerson({ ...person, contactEmail: e.target.value })
            }
            className="w-[75%] edit-fields"
          />
        </div>

        <div className="flex pt-3">
          <div className="w-[50%] text-field-grey font-semibold text-sm">
            Contact Number
          </div>
          <input
            value={person.contactNumber}
            name="contactNumber"
            onChange={(e: any) =>
              setPerson({ ...person, contactNumber: e.target.value })
            }
            className="w-[75%] edit-fields"
          />
        </div>

        <div className="flex pt-3">
          <div className="w-[50%] text-field-grey font-semibold text-sm">
            Designation
          </div>
          <input
            value={person.designation}
            name="designation"
            onChange={(e: any) =>
              setPerson({ ...person, designation: e.target.value })
            }
            className="w-[75%] edit-fields"
          />
        </div>
      </div>
      <div className="flex mt-6 justify-between w-[50%]">
        <button
          className="px-4 py-0.5 rounded-full bg-delete-red text-clarity"
          onClick={() => setEdit(false)}
        >
          CANCEL
        </button>
        <button
          type="submit"
          className="bg-pass-blue px-7 py-0.5 rounded-full text-clarity"
        >
          SAVE
        </button>
        <button
          className="px-4 py-0.5 rounded-full bg-delete-red text-clarity"
          type="button"
          onClick={() => {
            setEdit(false);
            updateContactPersons("remove", currentContact);
          }}
        >
          DELETE
        </button>
      </div>
    </form>
  );
};
