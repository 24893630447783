/**
 *  Get object value
 */
export function get<TReturn = unknown, TFallback = TReturn>(
  obj: object | null | undefined,
  path: string | string[],
  fallback?: TFallback
): TReturn | TFallback {
  if (!obj) return fallback as TFallback;
  const arr = typeof path === "string" ? path.split(".") : path;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = arr.reduce<any>(
    (xs, x) => (xs && xs[x] !== undefined ? xs[x] : undefined),
    obj
  );
  return value !== undefined ? value : fallback;
}
