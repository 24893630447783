import { FieldProps } from "@rjsf/utils";
import React from "react";

export const AgentInviteInfoField = ({
  formData
}: FieldProps) => {
  const { identity, space } = formData;

  return (
    <div>
      <p>
        You have been invited to the <strong>{space}</strong> Trixta space.
      </p>
      <p>
        To accept the invitation please login as
        <br />
        <strong>{identity}</strong>
      </p>
    </div>
  );
};
export default AgentInviteInfoField;
