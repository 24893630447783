import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    user: null,
    profile: {}
  },
  reducers: {
    setUser: (state: any, payload: any) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = payload.payload ? payload.payload : payload;
    },
    setUserRole: (state: any, payload: any) => {
      // Get the roles for the user agter authenticated
      console.log("setUserRole", setUserRole);
      state.user["trixtaMeta"]["roles"] = payload.payload.roles;
      console.log(payload.payload);
      console.log("got?");
    },
    setProfile: (state: any, payload: any) => {
      if (!payload.payload.result) {
        console.log(payload.payload.result);
      } else {
        state.profile = payload.payload.result;
        state.pfp = payload.payload.pfp;
      }
    },
    setPfp: (state: any, payload: any) => {
      state.pfp = payload.payload.pfp;
    },
    catchError: (state: any, payload: any) => {
      console.log(payload);
    }
  }
});

export const { setUser, setProfile, setUserRole, catchError, setPfp } =
  appSlice.actions;

export default appSlice.reducer;
