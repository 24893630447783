import { TrashIcon } from "@heroicons/react/20/solid";
import { usePopper } from "react-popper";
import { useState, useEffect, useRef } from "react";

export const DeletePropertyButton = ({ removePropertyFunction }: any) => {
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const popperElement = useRef(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement.current,
    {
      placement: "bottom" // Popup position
    }
  );

  useEffect(() => {
    const dummyElement = document.createElement("div");
    dummyElement.style.position = "fixed";
    setReferenceElement(dummyElement);
  }, []);

  return (
    <>
      {!deleteConfirm ? (
        <button
          onClick={() => {
            setDeleteConfirm(true);
            document.body.style.overflow = "hidden";
          }}
          className="flex justify-end w-full mb-6"
        >
          <TrashIcon className="text-delete-red w-5 h-5 mr-2" />
          <div className="mt-0.5 text-delete-red font-semibold text-xs self-middle">
            DELETE PROPERTY
          </div>
        </button>
      ) : (
        <>
          <div className="w-full h-[20px] mb-6"></div>
          <div
            className="fixed inset-0 bg-gray-700 opacity-50"
            onClick={() => {
              setDeleteConfirm(false);
              document.body.style.overflow = "auto";
            }} // Close the pop-up when clicking outside
          />
          <div
            ref={popperElement}
            style={{
              ...styles.popper,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "fixed"
            }}
            {...attributes.popper}
            className="w-64 fixed bg-white rounded shadow-lg p-6 text-center"
          >
            <div>
              <div className="text-delete-red font-semibold text-xs">
                Are you sure?
              </div>
              <div className="flex justify-end pt-4">
                <button
                  type="button"
                  className="bg-pass-blue w-24 rounded-full h-8 text-sm text-clarity"
                  onClick={() => {
                    setDeleteConfirm(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  CANCEL
                </button>
                <button
                  className="bg-delete-red w-24 ml-4 rounded-full h-8 text-sm text-clarity"
                  onClick={() => {
                    setDeleteConfirm(false);
                    document.body.style.overflow = "auto";
                    removePropertyFunction();
                  }}
                >
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
