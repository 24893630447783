import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";

import { useTrixta } from "../hooks/useTrixta";
import { PageTitle } from "../components/typography";

import { ReservedTrixtaRoles } from "@trixtateam/trixta-js-core";

import Role from "../components/roles/role";

const roles = [
  ReservedTrixtaRoles.EVERYONE_ANON,
  ReservedTrixtaRoles.EVERYONE_AUTHED
];

export default function RolesPage() {
  const [roleName, setRoleName] = useState("");
  const { auth } = useTrixta();

  // redirect to home if logged in
  useEffect(() => {
    if (!auth.isAuthorizing && !auth.isAuthenticated) {
      redirect("/login");
    }
  }, [auth]);

  return (
    <>
      <PageTitle>Roles</PageTitle>
      <div className="mb-4">
        <select value={roleName} onChange={e => setRoleName(e.target.value)}>
          {roles.map(role => (
            <option key={role} value={role}>
              {role}
            </option>
          ))}
        </select>
      </div>
      <Role roleName={roleName} />
    </>
  );
}
