import { Breadcrumbs } from '../breadcrumbs';
import { PageTitle } from '../typography';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface DetailsPageHeaderInterface  {
  headerTitle?: any;
  building?: string;
  building_id?: string;
}

export const DetailsPageHeader = ({headerTitle, building, building_id}: DetailsPageHeaderInterface) => {
  const navigate = useNavigate()

  return (
    <div className="pb-4 flex flex-row">
        <div>
          {building ? 
            <Breadcrumbs page='Buildings' building={building} building_id={building_id} /> :
            <Breadcrumbs page='Buildings' />
          }
          <div className="flex align-middle">
            <PageTitle>
              {headerTitle}
            </PageTitle>
          </div>
        </div>

        <button 
          onClick=
          {
            building ? 
              () => navigate(`/building-details/${building_id}`) : 
              () => navigate("/")
          }
          className='
            ml-auto 
            pr-4 pl-2 
            rounded-lg 
            flex items-center 
            transition duration-200 ease-in-out 
            hover:bg-hover-grey'>
          <ChevronLeftIcon className='w-6 h-6'/>
          <div 
            className='text-xs font-semibold text-focus-blue pl-2' 
            >
            RETURN
          </div>
        </button>
      </div>
  )
}