import { PencilSquareIcon } from "@heroicons/react/20/solid"
import { useState } from "react"
import { EditFeatures, EditDescription } from "../formEditComponants/editDescriptionFeatures"

export const DescriptionAndFeatures = ({ propertyObj, setPropertyObj }: any) => {

  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false)
  const [isEditingFeatures, setIsEditingFeatures] = useState<boolean>(false)

  return ( 
    <div>
      {!isEditingDescription ? 
      <div className="pt-6">
        <div className="flex justify-between items-start mb-4">
          <div className="text-focus-blue pr-4 text-lg font-semibold">Description</div>
          <div className="w-full h-4 border-b border-text-edit-grey">
          </div>
          <button onClick={() => setIsEditingDescription(true)}>
            <PencilSquareIcon className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey" />
          </button>
        </div>

        <p className="text-edit-grey pl-5 w-[90%] text-sm overflow-auto break-words whitespace-pre-wrap ">
          {propertyObj.specifications.description} 
        </p>
      </div> : 
      <EditDescription setEdit={setIsEditingDescription} propertyObj={propertyObj} setPropertyObj={setPropertyObj}/>} 
      
      {!isEditingFeatures ? 
      <div className="pt-6">
        <div className="flex justify-between items-start mb-4">
          <div className="text-focus-blue pr-4 text-lg font-semibold">Features</div>
          <div className="w-full h-4 border-b border-text-edit-grey">
          </div>
          <button onClick={() => setIsEditingFeatures(true)}>
            <PencilSquareIcon className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey" />
          </button>
        </div>

        <div className="text-edit-grey pl-5 w-[90%] text-sm overflow-auto break-words whitespace-pre-wrap ">
          {propertyObj.specifications.features}
        </div>
      </div> : 
      <EditFeatures setEdit={setIsEditingFeatures} propertyObj={propertyObj} setPropertyObj={setPropertyObj}/>}
    </div>
  )
}