export default function Container(props: { 
    children: React.ReactNode,
    className?: string,
    title?: string
}) {
    const { 
        children,
        className,
        title
    } = props;

    return (
        <>
            {title && (
                <div className="font-medium text-xs uppercase text-gray-400 mb-1 ml-4">
                    {title}
                </div>
            )}
            <div className={`bg-white p-4 rounded-xl ${className}`}>
                {children}
            </div>
        </>
    );
}