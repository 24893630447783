import { useState, useEffect } from "react";
import useUnitsHook from "../../hooks/useUnits";

export const PropertyValueTabel = ({
  unitObj,
  currentYear,
  setCurrentYear,
  propertyValues,
  setPropertyValues
}: any) => {
  const [edited, setEdited] = useState<any>(false);

  const { editUnit } = useUnitsHook();

  const onValueEdit = (e: any) => {
    e.persist();
    const { name, value } = e.target;

    setPropertyValues((prevValues: any) => ({
      ...prevValues,
      [currentYear]: { ...(prevValues[currentYear] || {}), [name]: value }
    }));

    setEdited(true);
  };

  useEffect(() => {
    if (unitObj.propertyValues) {
      setPropertyValues(unitObj.propertyValues);
    }
  }, [unitObj.propertyValues]);

  const resetValues = () => {
    setEdited(false);
    if (unitObj.propertyValues) {
      setPropertyValues(unitObj.propertyValues);
    } else {
      setPropertyValues({});
    }
  };

  const onSave = () => {
    setEdited(false);
    const tempUnitObj = { ...unitObj, propertyValues: propertyValues };
    editUnit(tempUnitObj);
  };

  const monthsTemplate = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  console.log(propertyValues);

  return (
    <div className="border-[1.5px] w-[40%] mr-8 mb-8">
      <div className="text-lg flex py-6 w-full justify-center">
        <button
          className="bg-gray-100 px-[9px] pb-[1px] font-bold rounded-full text-gray-200 hover:text-gray-500"
          onClick={() => {
            setCurrentYear(currentYear - 1);
          }}
        >
          {"<"}
        </button>

        <p className="px-4 text-focus-blue font-bold">{currentYear}</p>

        <button
          className="bg-gray-100 px-[9px] pb-[1px] font-bold rounded-full text-gray-200 hover:text-gray-500"
          onClick={() => {
            setCurrentYear(currentYear + 1);
          }}
        >
          {">"}
        </button>
      </div>
      <div className="flex flex-wrap ">
        {monthsTemplate.map((month, index) => {
          return (
            <div className="w-1/3 mb-6 text-center" key={index}>
              <div className="text-focus-blue">{month}</div>
              <input
                className="pt-1 focus:outline-none focus:bg-clarity text-sm text-center w-full"
                onChange={onValueEdit}
                name={month.toLowerCase()}
                placeholder={
                  !propertyValues[currentYear] ||
                  !propertyValues[currentYear][month.toLowerCase()]
                    ? "---"
                    : ""
                }
                value={
                  propertyValues[currentYear] &&
                  propertyValues[currentYear][month.toLowerCase()]
                    ? propertyValues[currentYear][month.toLowerCase()]
                    : ""
                }
              />
            </div>
          );
        })}
      </div>
      <div className="flex justify-around mx-4 mb-8 mt-2">
        <button
          disabled={!edited}
          className={`${
            edited ? "bg-pass-blue" : "bg-pass-blue/40"
          } px-7 py-0.5 rounded-full text-clarity`}
          onClick={() => {
            onSave();
          }}
        >
          SAVE
        </button>
        <button
          disabled={!edited}
          className={`px-7 py-0.5 rounded-full ${
            edited ? "bg-delete-red" : "bg-delete-red/40"
          } text-clarity`}
          onClick={() => {
            resetValues();
          }}
        >
          RESET
        </button>
      </div>
    </div>
  );
};
