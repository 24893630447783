import { TrixtaAction, TrixtaReaction } from "@trixtateam/trixta-js-core";
import { RootStateOrAny } from "react-redux";
import { createSelector } from "reselect";

// get all reactions for a given role
export const selectReactionsByRole = createSelector(
  [
    (state: RootStateOrAny) => state?.trixta?.reactions,
    (state: RootStateOrAny, roleName: string) => roleName
  ],
  (reactions, roleName) => {
    if (!reactions) return [];
    return Object.keys(reactions).reduce((acc: any, key: string) => {
      if (key.startsWith(`${roleName}:`)) {
        acc[key] = { ...(reactions[key] as TrixtaReaction) };
      }
      return acc;
    }, {});
  }
);

//get all actions for a given role
export const selectActionsByRole = createSelector(
  [
    (state: RootStateOrAny) => state?.trixta?.actions,
    (state: RootStateOrAny, roleName: string) => roleName
  ],
  (actions, roleName) => {
    if (!actions) return [];
    return Object.keys(actions).reduce((acc: any, key: string) => {
      if (key.startsWith(`${roleName}:`)) {
        acc[key] = { ...(actions[key] as TrixtaAction) };
      }
      return acc;
    }, {});
  }
);
