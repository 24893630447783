import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import usePropertiesHook from "../hooks/useProperties";
import useOwnerPropertiesHook from "../hooks/useOwnerProperties";
import PropertyList from "../components/propertyList/propertyList";
import { submitFormTemplate } from "../components/formTemplates/buildingTemplates";
import Loading from "../components/loading";
import { Breadcrumbs } from "../components/breadcrumbs";
import { loadThumbnail } from "../components/content/loadThumbnail";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { PageTitle } from "../components/typography";
import { RemoveTemplateProperties } from "../components/propertyList/removeTemplateBuildings";

const Buildings = () => {
  const { updateBuildings, addThumbnail, addThumbnailLoaded, removeBuilding } =
    usePropertiesHook();
  const { filterPropertiesHook } = useOwnerPropertiesHook();
  const navigate = useNavigate();
  const properties = useSelector((state: any) => state.properties);
  const user = useSelector((state: any) => state.app.user);

  const RemoveBuilding = (building: any) => {
    removeBuilding({ property_id: building?.property_id });
  };

  useEffect(() => {
    if (!properties.loading) {
      RemoveTemplateProperties(properties.properties, RemoveBuilding);
      console.log(properties);
    }
  }, [properties]);

  useEffect(() => {
    Object.keys(properties.buildingImages).forEach((buildingId: any) => {
      if (!properties.loadedThumbnails.includes(buildingId)) {
        addThumbnailLoaded(buildingId);
        loadThumbnail(
          properties.buildingImages[buildingId],
          buildingId,
          addThumbnail
        );
      }
    });
  }, [properties.buildingImages]);

  // If Property Owner role, loadProperty will be updated. Filter for owned properties
  useEffect(() => {
    if (!properties.loadProperties) {
      return;
    }

    setTimeout(() => {
      filterPropertiesHook(user.agent_id);
    }, 100);
  }, [properties.loadProperties]);

  const navToDetails = (itemIndex: number) => {
    navigate(`/building-details/${itemIndex}`);
  };

  const onClickAddBuilding = () => {
    const formData = {
      ...submitFormTemplate,
      name: "No Name",
      property_id: Date.now().toString(),
      manager: user.agent_id,
      new_template: true
    };
    setTimeout(() => {
      updateBuildings(formData, "add");
      navigate(`/building-details/new_${formData["property_id"]?.toString()}`);
    }, 200);
  };

  return (
    <div className="container content-center mx-auto">
      {!user ||
      !user.trixtaMeta ||
      !user.trixtaMeta.roles ||
      properties.loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <>
          <div className="pb-4">
            <Breadcrumbs />
            <div className="flex align-middle">
              <PageTitle>Buildings</PageTitle>
              <div className="flex items-center justify-between bg-search-grey rounded-xl mr-3 h-7">
                <button className="p-2">
                  <FunnelIcon className="h-3.5 w-3.5 text-focus-blue font-bold" />
                </button>
              </div>
              <div className="flex items-center justify-between bg-search-grey rounded-xl mr-9 h-7">
                <button className="p-2">
                  <MagnifyingGlassIcon className="h-3.5 w-3.5 text-focus-blue" />
                </button>
                <input
                  className="bg-transparent text-sm outline-none pl-1 py-2 pr-10 w-full text-black placeholder-gray-300"
                  type="text"
                  placeholder="SEARCH"
                />
              </div>
            </div>
          </div>
          <PropertyList
            properties={properties.properties}
            onEditClick={navToDetails}
            addBuilding={onClickAddBuilding}
          />
        </>
      )}
    </div>
  );
};

export default Buildings;
