import { viewBuildingFields } from "../formTemplates/buildingTemplates"
import { viewUnitFields } from "../formTemplates/unitTemplates"
import { PencilSquareIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react"

export const DetailsCardView = ({ building, propertyType, setEdit, mainImg}: any) => {
  const [thumbnail, setThumbnail] = useState<any>(undefined)
  let viewFields: any;

  if (propertyType === "building") {
    viewFields = viewBuildingFields
  } else {
    viewFields = viewUnitFields
  }  

  useEffect(() => {
    if (!mainImg) {
      return
    }

    if (mainImg == "empty") {
      setThumbnail("empty")
    }
    const img = new Image();
    img.src = mainImg[0][1];
    img.onload = () => setThumbnail(mainImg[0][1]);
  }, [mainImg])

  return (
    <div className="flex rounded-xl bg-field-grey h-72 relative">
      <button onClick={() => setEdit(true)}>
        <PencilSquareIcon className="edit-icon" />
      </button>
      {(thumbnail === "empty") ? 
      <div className='bg-hover-grey rounded-l-xl w-[30%] h-72 flex items-center justify-center'>
      </div> : 
      (thumbnail) ? 
        <img
          src={thumbnail}
          className="
            object-cover 
            rounded-l-xl
            w-[30%] h-72"
        />
      : <div className='bg-hover-grey rounded-l-xl w-[30%] h-72 flex items-center justify-center'>
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>}
      <div className="py-6 pl-10 w-[70%] grid grid-rows-6 grid-flow-col">
        {Object.keys(viewFields).map((key: string) => (
          <div className="flex" key={key}>
            <div className="pb-4 w-[40%] flex text-clarity">
              
              <div className="pb-0.5">
                {viewFields[key]["title"]}
                
              </div>
            </div>
            <div className="pb-4 w-[60%] flex text-clarity">
              
            <div className="pb-0.5">
              {building[key] ? building[key] : <>N/A</>}
              
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
  )
}