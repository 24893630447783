import { useSelector } from "react-redux";
import { Breadcrumbs } from "../components/breadcrumbs";
import { PageTitle } from "../components/typography";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { NormalView } from "../components/profileComponents/normalView";
import { EditView } from "../components/profileComponents/editView";
import { AddEditContactPerson } from "../components/profileComponents/addEditContactPerson";
import usePropertiesHook from "../hooks/useProperties";
import { useTrixta } from "../hooks/useTrixta";
import { loadSingleImage } from "../components/content/loadImages";

const Profile = () => {
  const profile = useSelector((state: any) => state.app.profile);
  const profilePicture = useSelector((state: any) => state.app.pfp);
  const { updateProfile } = usePropertiesHook();
  const { uploadPfp } = useTrixta();
  const [edit, setEdit] = useState(false);
  const [editingContact, setEditingContact] = useState(false);
  const [currentContact, setCurrentContact] = useState(0);
  const [profileData, setProfileData] = useState(profile);
  const [initPfp, setInitPfp] = useState<any>(undefined);
  const [tempPfp, setTempPfp] = useState<any>(undefined);

  useEffect(() => {
    setProfileData(profile);
  }, [profile]);

  useEffect(() => {
    if (profilePicture) {
      if (profilePicture.length > 0) {
        console.log("goin");
        loadSingleImage(profilePicture, setInitPfp);
      } else {
        setInitPfp("empty");
      }
    }
  }, [profilePicture]);

  useEffect(() => {
    if (!initPfp) {
      return;
    }

    if (initPfp == "empty") {
      setTempPfp("empty");
    }
    const img = new Image();
    img.src = initPfp;
    img.onload = () => setTempPfp(initPfp);
  }, [initPfp]);

  const onChangeEdit = (e: any) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const onSubmit = () => {
    updateProfile(profileData);
    if (tempPfp) {
      uploadPfp(tempPfp);
    }
  };

  const updateContacts = (
    operation: string,
    index: number,
    value: string,
    field: string
  ) => {
    const newContact = profileData.contactNumbers ?? [];

    switch (operation) {
      case "add":
        setProfileData({
          ...profileData,
          contactNumbers: [...newContact, { name: "", number: "" }]
        });
        break;
      case "remove":
        if (newContact.length > 1) {
          setProfileData((prevProfileData: any) => ({
            ...prevProfileData,
            contactNumbers: prevProfileData.contactNumbers.filter(
              (item: any, itemIndex: any) => itemIndex !== index
            )
          }));
        } else {
          setProfileData({
            ...profileData,
            contactNumbers: []
          });
        }
        break;
      case "edit":
        setProfileData((prevProfileData: any) => {
          const prevContacts = [...prevProfileData.contactNumbers];
          const prevContact = { ...prevContacts[index], [field]: value };
          prevContacts[index] = prevContact;

          return {
            ...prevProfileData,
            contactNumbers: prevContacts
          };
        });
        break;
      case "reset":
        setProfileData(profile);
        break;
      default:
        break;
    }
  };

  const updateContactPersons = (
    operation: string,
    index?: number,
    contact?: string
  ) => {
    const newContact = profileData.contactPersons ?? [];

    switch (operation) {
      case "add":
        if (profileData.contactPersons) {
          setCurrentContact(profileData.contactPersons.length);
        }

        const tempProfileData = [
          ...newContact,
          {
            contactEmail: "",
            contactName: "",
            contactNumber: "",
            contactSurname: "",
            designation: ""
          }
        ];

        setProfileData({
          ...profileData,
          contactPersons: tempProfileData
        });

        updateProfile({
          ...profile,
          contactPersons: tempProfileData
        });
        break;
      case "remove":
        if (newContact.length > 1) {
          const tempProfileData = profileData.contactPersons.filter(
            (item: any, itemIndex: any) => itemIndex !== index
          );
          setProfileData({
            ...profileData,
            contactPersons: tempProfileData
          });

          updateProfile({
            ...profile,
            contactPersons: tempProfileData
          });
        } else {
          setProfileData({
            ...profileData,
            contactPersons: []
          });

          updateProfile({
            ...profile,
            contactPersons: []
          });
        }
        break;
      case "edit":
        if (!index && index !== 0) {
          return;
        }

        setProfileData((prevProfileData: any) => {
          const prevContacts = [...prevProfileData.contactPersons];
          prevContacts[index] = contact;

          return {
            ...prevProfileData,
            contactPersons: prevContacts
          };
        });

        console.log("through");
        const prevContacts = [...profile.contactPersons];
        prevContacts[index] = contact;

        console.log(prevContacts);
        updateProfile({
          ...profile,
          contactPersons: prevContacts
        });
        break;
      default:
        break;
    }
  };

  console.log(tempPfp);

  return (
    <div className="container content-center mx-auto pr-8">
      <div className="pb-4">
        <Breadcrumbs />
        <div className="flex align-middle pb-4">
          <PageTitle>Profile</PageTitle>
        </div>

        {edit ? (
          <EditView
            setEdit={setEdit}
            profileData={profileData}
            onSubmit={onSubmit}
            onChange={onChangeEdit}
            updateContacts={updateContacts}
            setTempPfp={setTempPfp}
            profilePicture={tempPfp}
          />
        ) : (
          <NormalView
            setEdit={setEdit}
            profileData={profileData}
            profilePicture={tempPfp}
          />
        )}
        <div className="pt-8">
          <div className="flex justify-between items-start mb-4">
            <div className="text-semi-focus-blue w-[20%] pr-2 text-lg font-semibold">
              Contact Persons
            </div>
            <div className="w-full h-4 border-b border-text-edit-grey"></div>
          </div>

          <p className="text-edit-grey pl-5 w-[90%] text-sm overflow-auto break-words whitespace-pre-wrap "></p>
        </div>

        {!editingContact ? (
          <>
            <div className="flex justify-between items-start w-[85%] ml-5">
              <div className="flex items-center w-1/6">
                <div className="text-focus pr-4 text-sm font-semibold">
                  Name
                </div>
                <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
              </div>

              <div className="flex items-center w-1/5">
                <div className="px-4 text-sm font-semibold">Surname</div>
                <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
              </div>

              <div className="flex items-center w-1/3">
                <div className="px-4 text-sm font-semibold">Email</div>
                <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
              </div>

              <div className="flex items-center w-1/4">
                <div className="text-focus px-4 text-sm font-semibold">
                  Contact Number
                </div>
                <div className="flex-grow h-0.5 border-b border-text-edit-grey"></div>
              </div>

              <div className="flex items-center w-1/7">
                <div className="text-focus pl-4 text-sm font-semibold">
                  Designation
                </div>
              </div>
            </div>
            {profileData &&
              profileData.contactPersons &&
              profileData.contactPersons.map((person: any, index: number) => {
                if (!person) {
                  updateContactPersons("remove", index);
                }
                return (
                  <div key={index} className="flex w-full">
                    <div className="flex text-edit-grey justify-between items-start pt-6 w-[85%] ml-5">
                      <div className="flex items-center w-1/6">
                        <div className="text-focus pr-4 text-sm font-semibold">
                          {person.contactName ? person.contactName : "N/A"}
                        </div>
                      </div>

                      <div className="flex items-center w-1/5">
                        <div className="px-4 text-sm font-semibold">
                          {person.contactSurname
                            ? person.contactSurname
                            : "N/A"}
                        </div>
                      </div>

                      <div className="flex items-center w-1/3">
                        <div className="px-4 text-sm font-semibold">
                          {person.contactEmail ? person.contactEmail : "N/A"}
                        </div>
                      </div>

                      <div className="flex items-center w-1/4">
                        <div className="text-focus px-4 text-sm font-semibold">
                          {person.contactNumber ? person.contactNumber : "N/A"}
                        </div>
                      </div>

                      <div className="flex items-center w-1/7">
                        <div className="text-focus pl-4 text-sm font-semibold">
                          {person.designation ? person.designation : "N/A"}
                        </div>
                      </div>
                    </div>

                    <button
                      className="pt-6 w-[10%] flex justify-end"
                      onClick={() => {
                        setCurrentContact(index);
                        setEditingContact(true);
                      }}
                    >
                      <PencilSquareIcon className="w-6 h-6 text-edit-grey" />
                    </button>
                  </div>
                );
              })}

            <div className="flex pt-6 text-semi-focus-blue items-center">
              <div className="pr-2 text-2xl">+</div>
              <div className="text-xs font-semibold pt-0.5">
                <button
                  onClick={() => {
                    updateContactPersons("add");
                    setEditingContact(true);
                  }}
                >
                  ADD CONTACT PERSON
                </button>
              </div>
            </div>
          </>
        ) : (
          <AddEditContactPerson
            setEdit={setEditingContact}
            updateContactPersons={updateContactPersons}
            currentContact={currentContact}
            contactPerson={profileData.contactPersons[currentContact]}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
