import {
  submitTrixtaActionResponse,
} from "@trixtateam/trixta-js-core";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { AddPropertyFormData, RemovePropertyFormData } from "../components/propertyList/interfaces";
import { TrixtaOwnerActions } from "../constants/trixtaConstants";
import { 
  addPropertyState, 
  removePropertyState, 
  editPropertyState,
  catchError,
  filterProperties,
  catchTimeout,
  setOwnedPropertiesState
} from "../reducers/propertySlice";


const useOwnerPropertiesHook = () => {
  const dispatch = useDispatch();

  const addOwnedProperty = useCallback((formData: AddPropertyFormData) => {
    formData = {...formData, property_id: Date.now().toString()};
    console.log(formData)
    dispatch(submitTrixtaActionResponse({
      roleName: "property_owner",
      actionName: TrixtaOwnerActions.ADD_PROPERTY,
      requestEvent: addPropertyState as any,
      formData: formData
    }));
  }, [dispatch]);

  const getOwnedProperties = (): void => {
    console.log("gettingProp")
    dispatch(submitTrixtaActionResponse({
      roleName: "property_owner",
      actionName: TrixtaOwnerActions.REQUEST_PROPERTIES,
      responseEvent: setOwnedPropertiesState as any,
      timeoutEvent: catchTimeout as any,
      timeout: 60000
    }));
  }

  const editOwnedProperty = useCallback((formData: AddPropertyFormData) => {
    dispatch(submitTrixtaActionResponse({
      roleName: "property_owner",
      actionName: TrixtaOwnerActions.ADD_PROPERTY,
      requestEvent: editPropertyState as any,
      errorEvent: catchError as any,
      formData: formData
    }));
  }, [dispatch]);

  const removeOwnedProperty = useCallback((formData: RemovePropertyFormData) => {
    dispatch(submitTrixtaActionResponse({
      roleName: "property_owner",
      actionName: TrixtaOwnerActions.REMOVE_PROPERTY,
      requestEvent: removePropertyState as any,
      formData: formData,
    }));
  }, [dispatch]);

  const filterPropertiesHook = (email: any): void => {
    dispatch(
      filterProperties(email)
    )
  }

  return { 
    addOwnedProperty, 
    removeOwnedProperty, 
    editOwnedProperty,
    filterPropertiesHook,
    getOwnedProperties
  }
}

export default useOwnerPropertiesHook