import usePropertiesHook from "../../hooks/useProperties";


export const UploadContent = (imageData: string[], propertyType: string, propertyId: string, addContent: any, unitNumber?: string) => {

  const contentData = {
    "base64Image": imageData[1], 
    "imageName": imageData[0], 
    "propertyId": propertyId, 
    "propertyType": propertyType,
    "unitNumber": unitNumber
  }
  addContent(contentData)
  console.log(imageData)
  console.log(imageData)
}