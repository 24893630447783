export const FormField = ({ value, name, valid, onChange }: any) => {
  return (
    <div>
      {
      valid ? 
        <input 
          name={name} 
          value={value} 
          onChange={onChange}
          className="edit-fields border-gray-200"
        >
          
        </input> :
        <input 
          name={name} 
          value={value} 
          onChange={onChange}
          className="edit-fields border-gray-200"
        >
          
        </input>
      }
    </div>
  )
}

export const FormFieldError = ({ value, name, onChange }: any) => {
  return (
    <input name={name} value={value} onChange={onChange}>
    </input>
  )
}
