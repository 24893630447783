import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FunnelIcon } from "@heroicons/react/24/outline";
import {
  propertyListUnitTypes,
  propertyListUnitNames
} from "../TESTDATA/ManagerDash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PreLoad } from "../content/preLoadImg";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { BuildingCard } from "../propertyCards/buildingCard";
import { BuildingCardMobile } from "../propertyCards/buildingCardMobile";
import { motion } from "framer-motion";
import { Pagenation } from "./pagenation";
import { ManagerUnitSummary } from "./managerUnitSummary";

export const ManagerPropertyList = ({
  buildings,
  onEditClick,
  addBuilding,
  windowWidth
}: any) => {
  const stateThumbnails = useSelector(
    (state: any) => state.properties.buildingThumbnails
  );
  const [thumbnails, setThumbnails] = useState<any>({});
  const [filteredBuildings, setFilteredBuildings] = useState(buildings);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [typesSummary, setTypesSummary] = useState<string[]>([]);
  const [currentPageContent, setCurrentPageContent] = useState([]);
  const [filterBools, setFilterBools] = useState<any>({
    residential: false,
    commercial: false
  });

  const MAX_ITEMS = 6;

  useEffect(() => {
    setFilteredBuildings(buildings);
    setTypesSummary(ManagerUnitSummary(buildings));
  }, [buildings]);

  useEffect(() => {
    let endIndex;
    const startIndex = (currentPage - 1) * MAX_ITEMS;
    if (filteredBuildings.length === 1) {
      endIndex = 2;
    } else if (startIndex + MAX_ITEMS > filteredBuildings.length) {
      endIndex = filteredBuildings.length;
    } else {
      endIndex = startIndex + MAX_ITEMS;
    }

    setCurrentPageContent(filteredBuildings.slice(startIndex, endIndex));
  }, [currentPage, filteredBuildings]);

  useEffect(() => {
    if (!stateThumbnails) {
      return;
    }
    PreLoad(stateThumbnails, setThumbnails, thumbnails);
  }, [stateThumbnails]);

  const toggleFilter = (type: string) => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }

    if (filterBools[type]) {
      setFilteredBuildings(buildings);
      setFilterBools({ [type]: !filterBools[type] });
      return;
    }

    if (type == "commercial") {
      setFilteredBuildings(
        buildings.filter((building: any) => {
          return building.type === "Commercial";
        })
      );
      setFilterBools({ [type]: !filterBools[type], residential: false });
    } else {
      setFilteredBuildings(
        buildings.filter((building: any) => {
          return building.type === "Residential";
        })
      );
      setFilterBools({ [type]: !filterBools[type], commercial: false });
    }
  };

  const searchFilter = (e: any) => {
    console.log(e.target.value);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    if (e.target.value === "") {
      setFilteredBuildings(buildings);
    } else {
      setFilteredBuildings(
        buildings.filter((building: any) => {
          return building.name
            .toLowerCase()
            .startsWith(e.target.value.toLowerCase());
        })
      );
    }
  };

  return (
    <div>
      <div className="flex align-middle">
        <div className="font-bold text-focus-blue">Property Details</div>
        <button className="mr-auto">
          <PlusCircleIcon
            className="text-slate-400 w-7 h-7 ml-2"
            onClick={() => {
              addBuilding();
            }}
          />
        </button>
        <div className="flex items-center justify-between bg-search-grey rounded-xl mr-3 h-7">
          <button
            className="p-2"
            onClick={() => setFilterIsOpen(!filterIsOpen)}
          >
            <FunnelIcon className="h-3.5 w-3.5 text-focus-blue font-bold" />
          </button>
        </div>
        <div className="flex items-center justify-between bg-search-grey rounded-xl mr-9 h-7">
          <button className="p-2">
            <MagnifyingGlassIcon className="h-3.5 w-3.5 text-focus-blue" />
          </button>
          <input
            className="bg-transparent text-sm outline-none pl-1 py-2 w-full text-black placeholder-gray-300"
            type="text"
            placeholder="SEARCH"
            onChange={searchFilter}
          />
        </div>
      </div>

      <div className={`filter-dropdown ${filterIsOpen ? "open" : ""}`}>
        <div className="flex pl-6 pt-6">
          <div className="text-edit-grey font-bold text-xs pt-1">Filter By</div>
          <div className="flex rounded-full text-edit-grey text-xs font-bold ml-14 border-placeholder-gray-300 border-[1.5px]">
            <button
              onClick={() => toggleFilter("residential")}
              className={`px-2 h-5 border-placeholder-gray-300 rounded-l-full border-r-[1.5px] ${
                filterBools["residential"] ? "bg-delete-red text-clarity" : ""
              }`}
            >
              Residential
            </button>
            <button
              onClick={() => toggleFilter("commercial")}
              className={`px-2 rounded-r-full ${
                filterBools["commercial"] ? "bg-pass-blue text-clarity" : ""
              }`}
            >
              Commercial
            </button>
          </div>
        </div>
      </div>
      {windowWidth > 1260 ? (
        <motion.div layout className="property-grid pl-6 mt-6">
          {currentPageContent.map((property: any, index: any) => {
            const { name, property_id, type } = property;

            return (
              <div
                key={property_id}
                className={`ease-in-out transition duration-700 
                 ${
                   ""
                   //   index === 0 &&
                   //   (filterBools["residential"] || filterBools["commercial"])
                   //     ? "managerbuildingFirst"
                   //     : index === 1 &&
                   //       (filterBools["residential"] || filterBools["commercial"])
                   //     ? "managerbuildingSecond"
                   //     : " "
                 }`}
              >
                {filterBools[type.toLowerCase()] ||
                !(filterBools["residential"] || filterBools["commercial"]) ? (
                  <BuildingCard
                    name={name}
                    property_id={property_id}
                    propertyType={type}
                    onEditClick={onEditClick}
                    thumbnails={thumbnails}
                    stateThumbnails={stateThumbnails}
                    typesSummary={typesSummary}
                    types={property.units_types ? property.units_types : {}}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </motion.div>
      ) : (
        <div className="pl-6 mt-6">
          {currentPageContent.map((property: any, index: any) => {
            const { name, property_id, type } = property;
            return (
              <>
                <BuildingCardMobile
                  name={name}
                  index={index}
                  property_id={property_id}
                  propertyType={type}
                  onEditClick={onEditClick}
                  thumbnails={thumbnails}
                  stateThumbnails={stateThumbnails}
                  propertyListUnitTypes={propertyListUnitTypes}
                  propertyListUnitNames={propertyListUnitNames}
                />
              </>
            );
          })}
        </div>
      )}
      <Pagenation
        itemsAmount={filteredBuildings.length}
        pageNumber={currentPage}
        setPageNumber={setCurrentPage}
        maxItems={MAX_ITEMS}
      />
    </div>
  );
};
