import { FieldProps } from "@rjsf/utils";
import React, { useEffect } from "react";

export function ErrorMessage({
  formData = ""
}: FieldProps) {
  const [error, setError] = React.useState<any>({});

  useEffect(() => {
    if (formData) setError(formData);
  }, [formData]);

  if (!error?.status) return null;

  return (
    <div
      className="p-2 mb-2 text-sm text-red-800 rounded-lg bg-red-50"
      role="alert"
    >
      {error?.message}
    </div>
  );
}
