import { createSlice } from "@reduxjs/toolkit";

export const verifySlice = createSlice({
  name: "verify",
  initialState: {
    error: null,
    response: null
  },
  reducers: {
    setResponse: (state: any, payload: any) => {
      console.log("setResponse", payload);
      state.response = payload.payload ? payload.payload : payload;
      state.error = null;
    },
    setError: (state: any, payload: any) => {
      console.log("setError", payload);
      state.error = payload.payload ? payload.payload : payload;
      state.response = null;
    },
    clear: (state: any, payload: any) => {
      console.log("clear", payload);
      state.error = null;
      state.response = null;
    }
  }
});

export const { setError, clear, setResponse } = verifySlice.actions;

export default verifySlice.reducer;
