import { useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import React, { useEffect, useState } from "react";
import useUnitsHook from "../hooks/useUnits";

import { DetailsPageHeader } from "../components/formViewComponants/detailsPageHeader";
import { DetailsCardView } from "../components/formViewComponants/detailsCardView";
import { Tags } from "../components/formViewComponants/tags";
import { AdditionalImages } from "../components/formViewComponants/additionalImages";
import { DescriptionAndFeatures } from "../components/formViewComponants/descriptionAndFeatures";
import { EditDetailsCard } from "../components/formEditComponants/editDetailsCard";
import { Tenants } from "../components/formViewComponants/tenants";
import { UnitCommercials } from "../components/formViewComponants/unitCommercials";
import { UnitOperations } from "../components/formViewComponants/propertyOperations";
import { UnitCommercialsTabs } from "../components/unitCommercials";
import { Decode } from "../components/content/decode";

import { addUnitFields } from "../components/formTemplates/unitTemplates";
import { loadImages } from "../components/content/loadImages";

import { DeletePropertyButton } from "../components/formViewComponants/deletePropertyButton";

const UnitDetails = () => {
  const properties = useSelector((state: any) => state.properties.units);
  const buildings = useSelector((state: any) => state.properties.properties);
  const unitImages = useSelector((state: any) => state.properties.unitImages);
  const user = useSelector((state: any) => state.app.user);

  const navigate = useNavigate();

  const [unitObj, setUnitObj] = useState<any>(undefined);
  const [currentUnitImages, setCurrentUnitImages] = useState<any>([]);

  const [mainImg, setMainImg] = useState<any>(undefined);
  const [mainImgLoaded, setMainImgLoaded] = useState<boolean>(false);

  const [isEditingDetailsCard, setIsEditingDetailsCard] =
    useState<boolean>(false);

  const { getUnits, removeUnit, editUnit, getUnitImages } = useUnitsHook();

  let { slug: unit_number } = useParams();

  let currentPath: any = useLocation();
  currentPath = currentPath.pathname.split("/");
  const buildingId = currentPath[2];

  const building = buildings.find((obj: any) => obj.property_id === buildingId);

  const checkImages = () => {
    console.log("checking unit images");
    console.log(unitImages);
    if (
      unit_number &&
      unitImages[buildingId] &&
      unitImages[buildingId][unit_number]
    ) {
      setCurrentUnitImages(unitImages[buildingId][unit_number]);
      console.log("Setting unit images");
    }
  };

  useEffect(() => {
    checkImages();
  }, [unitImages]);

  useEffect(() => {
    if (buildings.length > 0) {
      getUnits({ property_id: buildingId });
      if (!unitImages[buildingId]) {
        getUnitImages({ property_id: buildingId });
      }
    }
  }, [building]);

  useEffect(() => {
    console.log("Reloading");
    if (unitObj) {
      return;
    }

    console.log("Passed unit check");

    const units = properties[currentPath[2]];
    let unit;

    if (unit_number) {
      if (unit_number.startsWith("new_")) {
        setIsEditingDetailsCard(true);
        unit_number = unit_number.slice(4);
        console.log(unit_number);
      }
      if (units) {
        unit = properties[currentPath[2]].find(
          (obj: any) => obj.unit_id === unit_number
        );
        console.log(properties[currentPath[2]]);
        console.log(unit);
        setUnitObj(unit);
      }
    }
  }, [properties]);

  console.log(properties);

  useEffect(() => {
    if (mainImgLoaded) {
      return;
    }

    if (unitObj && currentUnitImages) {
      if (currentUnitImages.length === 0) {
        setMainImg("empty");
        setMainImgLoaded(true);
        return;
      }

      if (Decode(currentUnitImages[0][0]) !== "thumbnail") {
        setMainImgLoaded(true);
        return;
      }
      const currentImg: string[] = [currentUnitImages[0]];
      loadImages(currentImg, setMainImg, setMainImgLoaded);
    }
  }, [currentUnitImages]);

  const onSubmit = () => {
    setUnitObj({ ...unitObj, new_template: false });
    editUnit(unitObj);
  };

  const deleteUnit = () => {
    removeUnit({
      property_id: building.property_id,
      unit_number: unitObj.unit_id
    });
    navigate(`/building-details/${building.property_id}`);
  };

  console.log(currentUnitImages);
  console.log(mainImg);

  return (
    <>
      {!user ||
      !user.trixtaMeta ||
      !user.trixtaMeta.roles ||
      !building ||
      !unitObj ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <DetailsPageHeader
            headerTitle={unitObj.name ? unitObj.name : "No Name"}
            building={building.name ? building.name : "No Name"}
            building_id={building.property_id}
          />

          {isEditingDetailsCard ? (
            <EditDetailsCard
              setEdit={setIsEditingDetailsCard}
              setPropertyObj={setUnitObj}
              propertyObj={unitObj}
              template={addUnitFields}
              propertyType="unit"
              setMainImg={setMainImg}
              mainImg={mainImg}
              onSubmit={onSubmit}
            />
          ) : (
            <DetailsCardView
              building={unitObj}
              propertyType="unit"
              setEdit={setIsEditingDetailsCard}
              mainImg={mainImg}
            />
          )}

          <UnitCommercialsTabs unitObj={unitObj} />

          <UnitCommercials unitObj={unitObj} setUnitObj={setUnitObj} />

          <UnitOperations unitObj={unitObj} setUnitObj={setUnitObj} />

          <Tags
            tags={unitObj.tags}
            setPropertyObj={setUnitObj}
            propertyObj={unitObj}
            propertyType="unit"
          />

          <AdditionalImages
            images={currentUnitImages}
            property_id={building.property_id}
            unit_id={unitObj.unit_id}
            setPropertyObj={setUnitObj}
            propertyObj={unitObj}
          />

          <DescriptionAndFeatures
            propertyObj={unitObj}
            setPropertyObj={setUnitObj}
          />

          <Tenants unitObj={unitObj} setUnitObj={setUnitObj} />

          <DeletePropertyButton removePropertyFunction={deleteUnit} />
        </div>
      )}
    </>
  );
};

export default UnitDetails;
