import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectActionsByRole, selectReactionsByRole } from "../reducers/trixta";
import { updateTrixtaRole } from "@trixtateam/trixta-js-core";
import { setError, setResponse, clear } from "../reducers/roleSlice";

const useRole = ({ roleName }: { roleName: string }) => {
  const dispatch = useDispatch();
  const loaded = useSelector(
    (state: any) => state.trixta.agentDetails[roleName]
  );
  const errors = useSelector((state: any) => state.role.errors);
  const responses = useSelector((state: any) => state.role.responses);
  const reactions = useSelector(
    (state: any) => selectReactionsByRole(state, roleName),
    shallowEqual
  );
  const actions = useSelector(
    (state: any) => selectActionsByRole(state, roleName),
    shallowEqual
  );

  useEffect(() => {
    dispatch(updateTrixtaRole({ name: roleName }));
  }, [dispatch, roleName]);

  return {
    roleName: loaded ? roleName : null,
    actions,
    reactions,
    errors,
    responses,
    setError,
    setResponse,
    clear
  };
};

export default useRole;
