import { useState, useEffect } from "react"
import { PencilSquareIcon } from "@heroicons/react/20/solid"
import { UnitEditOperations } from "../formEditComponants/UnitEditOperations"

export const UnitOperations = ({ unitObj, setUnitObj }: any) => {
  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false)
  useEffect(() => {
    if (!(unitObj.operations)) {
      setUnitObj({...unitObj, operations: {}})
    } 
  }, [])

  return (
    <div>
      <div className="py-6 w-[100%] ">
        <div className="flex justify-between items-start mb-4">
          <div className="text-focus-blue pr-4 text-lg font-semibold">Property Operations</div>
          <div className="w-full h-4 border-b border-text-edit-grey">
          </div>
          <button onClick={() => setIsEditingDescription(true)}>
            <PencilSquareIcon className="w-6 h-6 top-0 right-0 mx-4 mt-1 text-edit-grey" />
          </button>
        </div>
        {(!isEditingDescription) ? 
        <div className="grid grid-rows-4 grid-flow-col w-[100%]">
          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Furniture pack
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.furniture ? unitObj.operations.furniture : <>N/A</>}
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Furniture pack Type
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.packType ? unitObj.operations.packType : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Door lock type
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.doorLock ? unitObj.operations.doorLock : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2 border-r-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Automation system
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.system ? unitObj.operations.system : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              WIFI Password
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.wifi ? unitObj.operations.wifi : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Appliances
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5 overflow-auto break-words w-[50%]">
                {unitObj.operations.appliances ? unitObj.operations.appliances : <>N/A</>}
                
              </div>
            </div>
          </div>

          <div className="flex w-[30rem] border-b-2">
            <div className="pb-4 w-[40%] flex"> 
              <div className="pb-0.5">
              Appliance brands
              </div>
            </div>
            <div className="pb-4 w-[60%] flex"> 
              <div className="pb-0.5">
                {unitObj.operations.brands ? unitObj.operations.brands : <>N/A</>}
                
              </div>
            </div>
          </div>
        </div> : 
        <UnitEditOperations currentUnit={unitObj} setCurrentUnit={setUnitObj} setEditing={setIsEditingDescription} />}
      </div>
    </div>
  )
}