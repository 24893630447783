export const BuildingCardMobile = ({
  name,
  index,
  property_id,
  propertyType,
  onEditClick,
  thumbnails,
  stateThumbnails,
  propertyListUnitTypes,
  propertyListUnitNames
}: any) => {
  return (
    <div className="flex flex-col items-center  mt-14" key={property_id}>
      <div className="flex pb-4 pr-10">
        <p
          className={`pr-2 font-bold ${
            propertyType === "Residential"
              ? "text-delete-red"
              : "text-pass-blue"
          }`}
        >
          {name}
        </p>
        <p className="font-bold text-edit-grey">{propertyType}</p>
      </div>
      <button
        className="rounded-3xl border-placeholder-gray-300 border-[1px]  w-[95%] max-w-[20rem] mr-10"
        onClick={() => onEditClick(property_id)}
      >
        <div
          className={`rounded-t-3xl h-[1.5rem] w-full ${
            propertyType === "Residential" ? "bg-delete-red" : "bg-pass-blue"
          }`}
        ></div>
        <div className="h-[12rem] w-full">
          {thumbnails[property_id] ? (
            <img
              src={thumbnails[property_id]}
              alt={`Missing`}
              loading="lazy"
              className="w-full h-full object-cover"
              onClick={() => onEditClick(property_id)}
            />
          ) : stateThumbnails[property_id] === "empty" ? (
            <div
              className="bg-hover-grey w-full h-full flex items-center justify-center"
              onClick={() => onEditClick(property_id)}
            ></div>
          ) : (
            <div className="bg-hover-grey w-full h-full flex items-center justify-center">
              <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          )}
        </div>
        <div className="pl-5 pt-4 grid grid-cols-2 rounded-b-3xl border-placeholder-gray-300 border-[1px] border-t-[0px]">
          {propertyListUnitTypes[0].map((prop: any, index: number) => {
            return (
              <div className="flex pb-3" key={index}>
                <p className="w-[2rem] text-focus-blue font-bold text-xl">
                  {prop}
                </p>
                <p className="w-[2rem] pt-1 pl-2 font-bold text-edit-grey text-sm">
                  {propertyListUnitNames[index]}
                </p>
              </div>
            );
          })}
        </div>
      </button>
    </div>
  );
};
