import { submitTrixtaActionResponse } from "@trixtateam/trixta-js-core";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  PropertyBase,
  RemovePropertyFormData
} from "../components/propertyList/interfaces";
import {
  TrixtaManagerActions,
  TrixtaOwnerActions
} from "../constants/trixtaConstants";
import {
  setPropertiesState,
  addPropertyState,
  editPropertyState,
  removePropertyState,
  catchError,
  startLoader,
  pushThumbnailStart,
  pushUnitThumbnailStart,
  catchTimeout,
  addTempImage,
  setLoadedThumbnail,
  addThumbnailLoadedState,
  addEmptyImagesBuilding
} from "../reducers/propertySlice";

import { setProfile } from "../reducers/appSlice";

const usePropertiesHook = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.app.user);
  // const userTrix = useSelector((state: any) => state.app.initialState)
  let userRole: string;
  let trixtaActions: any;

  if (user && user.trixtaMeta && user.trixtaMeta.roles) {
    if (user.trixtaMeta.roles.includes("property_manager")) {
      userRole = "property_manager";
      trixtaActions = TrixtaManagerActions;
    } else {
      userRole = "property_owner";
      trixtaActions = TrixtaOwnerActions;
    }
  }

  const setLoading = (): void => {
    dispatch(startLoader());
  };

  const getBuildings = (): void => {
    console.log("Retrieving buildings");
    dispatch(
      submitTrixtaActionResponse({
        roleName: userRole,
        actionName: trixtaActions.REQUEST_PROPERTIES,
        responseEvent: setPropertiesState as any,
        timeoutEvent: catchTimeout as any,
        errorEvent: catchError as any,
        timeout: 60000
      })
    );
  };

  const getContent = () => {
    console.log(userRole);
    dispatch(
      submitTrixtaActionResponse({
        roleName: userRole,
        actionName: trixtaActions.GET_CONTENT,
        timeoutEvent: catchTimeout as any,
        errorEvent: catchError as any,
        timeout: 60000
      })
    );
  };

  const getProfile = () => {
    dispatch(
      submitTrixtaActionResponse({
        roleName: userRole,
        actionName: trixtaActions.GET_PROFILE,
        timeoutEvent: catchTimeout as any,
        responseEvent: setProfile as any,
        errorEvent: catchError as any,
        timeout: 60000
      })
    );
  };

  const updateProfile = (payload: any) => {
    dispatch(
      submitTrixtaActionResponse({
        roleName: userRole,
        actionName: trixtaActions.UPDATE_PROFILE,
        timeoutEvent: catchTimeout as any,
        requestEvent: setProfile as any,
        errorEvent: catchError as any,
        formData: payload,
        timeout: 60000
      })
    );
  };

  const addThumbnail = (payload: any) => {
    dispatch(setLoadedThumbnail(payload));
  };

  const addThumbnailLoaded = (payload: any) => {
    dispatch(addThumbnailLoadedState(payload));
  };

  const pushThumbnail = (payload: any): void => {
    if (payload.unit_id) {
      console.log("pushin");
      dispatch(pushUnitThumbnailStart(payload));
    } else {
      dispatch(pushThumbnailStart(payload));
    }
  };

  const updateBuildings = async (inpFormData: PropertyBase, action: string) => {
    let formData: any = inpFormData;
    let requestEvent = addPropertyState as any;
    if (action === "edit") {
      requestEvent = editPropertyState as any;
      formData = { ...formData, new_template: false };
    } else if (action == "add") {
      await dispatch(addEmptyImagesBuilding(formData));
    }

    await dispatch(
      submitTrixtaActionResponse({
        roleName: userRole,
        actionName: trixtaActions.ADD_PROPERTY,
        requestEvent: requestEvent,
        errorEvent: catchError as any,
        timeout: 60000,
        formData: formData
      })
    );
  };

  const addContent = (formData: any) => {
    if (formData.imageName !== "thumbnail") {
      dispatch(addTempImage(formData));
    }

    try {
      dispatch(
        submitTrixtaActionResponse({
          roleName: "everyone_authed",
          actionName: trixtaActions.ADD_CONTENT,
          errorEvent: catchError as any,
          formData: formData,
          timeout: 60000
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const removeBuilding = useCallback(
    (formData: RemovePropertyFormData) => {
      console.log(trixtaActions);
      dispatch(
        submitTrixtaActionResponse({
          roleName: userRole,
          actionName: trixtaActions.REMOVE_PROPERTY,
          requestEvent: removePropertyState as any,
          errorEvent: catchError as any,
          formData: formData,
          timeout: 60000
        })
      );
    },
    [dispatch]
  );

  return {
    getBuildings,
    updateBuildings,
    addContent,
    removeBuilding,
    pushThumbnail,
    setLoading,
    getContent,
    addThumbnail,
    addThumbnailLoaded,
    getProfile,
    updateProfile
  };
};

export default usePropertiesHook;
