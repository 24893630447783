import { motion, AnimatePresence } from "framer-motion";

export const BuildingCard = ({
  name,
  property_id,
  propertyType,
  onEditClick,
  thumbnails,
  stateThumbnails,
  typesSummary,
  types
}: any) => {
  return (
    <AnimatePresence>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        layout
        key={property_id}
      >
        <div className="flex pb-4 pl-2">
          <p
            className={`pr-2 font-bold ${
              propertyType === "Residential"
                ? "text-delete-red"
                : "text-pass-blue"
            }`}
          >
            {name}
          </p>
          <p className="font-bold text-edit-grey">{propertyType}</p>
        </div>
        <button
          className="flex shadow-right-bottom rounded-3xl border-placeholder-gray-300 border-[1px] h-[12rem] w-min-[22rem] mr-10 mb-6"
          onClick={() => onEditClick(property_id)}
        >
          <div
            className={`rounded-l-3xl w-[1.5rem] h-full ${
              propertyType === "Residential" ? "bg-delete-red" : "bg-pass-blue"
            }`}
          ></div>
          <div className="w-[12rem] h-full">
            {thumbnails[property_id] ? (
              <img
                src={thumbnails[property_id]}
                alt={`Missing`}
                loading="lazy"
                className="w-full h-full object-cover"
                onClick={() => onEditClick(property_id)}
              />
            ) : stateThumbnails[property_id] === "empty" ? (
              <div
                className="bg-hover-grey w-full h-full flex items-center justify-center"
                onClick={() => onEditClick(property_id)}
              ></div>
            ) : (
              <div className="bg-hover-grey w-full h-full flex items-center justify-center">
                <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
              </div>
            )}
          </div>
          <div className="pt-5 pl-5 grid grid-rows-4 grid-flow-col ">
            {typesSummary.map((prop: any, index: number) => {
              if (prop === "") {
                return;
              }
              return (
                <div className="flex pb-3 pr-10" key={index}>
                  <p className="text-focus-blue font-bold text-xl">
                    {types[prop] ? types[prop] : 0}
                  </p>
                  <p className="pt-1 pl-3 font-bold text-edit-grey text-sm">
                    {prop}
                  </p>
                </div>
              );
            })}
          </div>
        </button>
      </motion.div>
    </AnimatePresence>
  );
};
