import { useState, useEffect, useRef } from "react";
import { loadImages } from "../content/loadImages";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { UploadContent } from "../content/uploadContent";
import usePropertiesHook from "../../hooks/useProperties";
import { Decode } from "../content/decode";
import { useSelector } from "react-redux";
import { usePopper } from "react-popper";
import { ImageCarousel } from "./imageCarousel";

export const AdditionalImages = ({
  images,
  property_id,
  unit_id,
  propertyObj,
  setPropertyObj
}: any) => {
  const [currentImages, setCurrentImages] = useState<any>(undefined);
  const [carousel, setCarousel] = useState<any>(false);
  const [carouselIndex, setCarouselIndex] = useState<any>(0);

  const user = useSelector((state: any) => state.app.user);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { addContent } = usePropertiesHook();

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // unitize image add/upload (repeated in 3 locations)
  const handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);
    files.forEach((file: any) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const imageData = e.target.result;
          setPropertyObj({ ...propertyObj, new_template: false });
          setCurrentImages([...currentImages, [file.name, imageData]]);
          if (!unit_id) {
            UploadContent(
              [file.name, imageData],
              "property",
              property_id,
              addContent
            );
          } else {
            UploadContent(
              [file.name, imageData],
              "unit",
              property_id,
              addContent,
              unit_id
            );
          }
        };
        reader.readAsDataURL(file);
      }
    });
  };

  // const handleRemoveImg = (index: number) => {

  // }

  useEffect(() => {
    console.log("checking images");
    if (!images || images.length === 0) {
      setCurrentImages([]);
      return;
    }

    if (Decode(images[0][0]) === "thumbnail") {
      loadImages(images.slice(1), setCurrentImages);
    } else {
      loadImages(images, setCurrentImages);
    }
  }, [images]);

  return (
    <div className="pt-6 relative">
      <div className="flex justify-between items-start mb-4">
        <div className="text-focus-blue text-lg font-semibold">
          Additional Photos
        </div>
      </div>
      <div className="flex flex-row flex-wrap">
        {currentImages ? (
          <>
            {currentImages.map((image: any, index: any) => {
              return (
                <div
                  key={index}
                  className="w-[12rem] h-[8rem] rounded-xl mr-4 mt-4 cursor-pointer"
                  onClick={() => {
                    setCarousel(true);
                    setCarouselIndex(index);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <img
                    src={image[1]}
                    key={index}
                    className="absolute object-cover rounded-xl w-[12rem] h-[8rem]"
                  ></img>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
        <div
          className="
          w-[12rem] 
          h-[8rem] 
          bg-hover-grey 
          rounded-xl 
          flex 
          items-center 
          justify-center
          mt-4"
          onClick={handleIconClick}
        >
          <PlusCircleIcon className="w-10 h-10 text-edit-grey" />
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          multiple
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      {carousel && currentImages && (
        <ImageCarousel
          setCarousel={setCarousel}
          carouselIndex={carouselIndex}
          setCarouselIndex={setCarouselIndex}
          currentImages={currentImages}
        />
      )}
    </div>
  );
};
