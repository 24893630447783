import { usePopper } from "react-popper";
import { useState, useRef, useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export const ImageCarousel = ({
  setCarousel,
  carouselIndex,
  setCarouselIndex,
  currentImages
}: any) => {
  const [carouselElement, setCarouselElement] = useState<any>(null);
  const carouselPopperElement = useRef(null);
  const { styles, attributes } = usePopper(
    carouselElement,
    carouselPopperElement.current,
    {
      placement: "bottom" // Popup position
    }
  );

  useEffect(() => {
    const dummyElement = document.createElement("div");
    dummyElement.style.position = "fixed";
    setCarouselElement(dummyElement);
  }, []);

  return (
    <div>
      <div
        className="fixed inset-0 bg-gray-700 opacity-50 z-10"
        onClick={() => {
          setCarousel(false);
          document.body.style.overflow = "auto";
        }} // Close the pop-up when clicking outside
      />
      <div
        ref={carouselPopperElement}
        style={{
          ...styles.popper,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed"
        }}
        className="z-10 fixed bg-white rounded shadow-lg p-6 h-[80%] w-[70%]"
        {...attributes.popper}
      >
        <div className="flex items-center justify-between h-[92%]">
          <button
            onClick={() => {
              if (carouselIndex > 0) {
                setCarouselIndex(carouselIndex - 1);
              } else {
                setCarouselIndex(currentImages.length - 1);
              }
            }}
          >
            <ChevronLeftIcon className="w-6 h-10 hover:p-[1px] hover:border-edit-gray hover:text-gray-400" />
          </button>
          <div className="h-full">
            <img
              src={currentImages[carouselIndex][1]}
              className="object-cover h-full px-4"
            ></img>
          </div>
          <button
            onClick={() => {
              if (carouselIndex < currentImages.length - 1) {
                setCarouselIndex(carouselIndex + 1);
              } else {
                setCarouselIndex(0);
              }
            }}
          >
            <ChevronRightIcon className="w-6 h-10 hover:p-[1px] hover:border-edit-gray hover:text-gray-400" />
          </button>
        </div>
        <div className="text-center my-4">{`${carouselIndex + 1}/${
          currentImages.length
        }`}</div>
      </div>
    </div>
  );
};
