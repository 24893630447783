import { ThemeProps } from "@rjsf/core";
import { RegistryFieldsType } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import AgentInviteInfoField from "./customFields/agentInviteInfo";
import { setJsonSchemaFormProps } from "@trixtateam/trixta-js-rjsf";
import { GoogleAuthField } from "./customFields/googleAuth";
import { TextLink } from "./customFields/textLink";
import { ErrorMessage } from "./customFields/errorMessage";

export const customFields: RegistryFieldsType = {
  trixta_invite_info: AgentInviteInfoField,
  trixta_google_auth: GoogleAuthField,
  trixta_text_link: TextLink,
  trixta_error_message: ErrorMessage
};

export const customTheme: ThemeProps = {
  fields: customFields
};

export const setupCustomFormTemplate = () => {
  setJsonSchemaFormProps({
    validator,
    noHtml5Validate: true,
    liveValidate: false,
    fields: customFields,
    className: "project-template",
    formContext: {}
  });
};
