import { all, fork } from 'redux-saga/effects';
import { contentSaga, unitContentSaga } from './reactionSaga';
import { authSuccessSaga } from './authSaga';

export default function* rootSaga() {
  yield all([
    fork(contentSaga),
    fork(unitContentSaga),
    fork(authSuccessSaga)
    // here you could add other sagas if you have more...
    // fork(anotherSaga),
    // fork(yetAnotherSaga),
    // etc.
  ]);
}