import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HomeIcon, UserIcon, Bars3Icon } from "@heroicons/react/20/solid";
import usePropertiesHook from "../../hooks/useProperties";
import React, { useEffect, useState } from "react";
import { useTrixta } from "../../hooks/useTrixta";
import NavRectangle from "../../assets/Rectangle 114.png";
import Logo from "../../assets/Layer 1.png";
import { ReactComponent as ServiceIcon } from "../../assets/Group 125.svg";
import { ReactComponent as ServiceIconFocus } from "../../assets/AI Icon Blue.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

export default function Layout({ children }: { children?: React.ReactNode }) {
  const { auth, logout, getRoles } = useTrixta();
  const { getBuildings, getContent, getProfile } = usePropertiesHook();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const [svgHover, setSvgHover] = useState(false);

  const user = useSelector((state: any) => state.app.user);
  const buildings = useSelector((state: any) => state.properties.properties);

  const navigate = useNavigate();

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (windowWidth < 1080) {
      setHamburger(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!auth?.isAuthenticated && auth?.isAuthenticated !== undefined) {
      return navigate("/login");
    }
    console.log(`User status $`);
    if (user && user.trixtaMeta && !user.trixtaMeta.roles) {
      console.log("if ever");
      setTimeout(() => getRoles({ agent_id: user.agent_id }), 200);
    }
  }, [user]);

  useEffect(() => {
    if (!auth?.isAuthenticated && auth?.isAuthenticated !== undefined) {
      return navigate("/login");
    }

    if (!user || !user.trixtaMeta) {
      return;
    }

    console.log(auth);

    if (
      user.trixtaMeta.roles &&
      buildings.length === 0 &&
      auth.isAuthenticated
    ) {
      getBuildings();
      console.log("get content");
      getContent();
      getProfile();
    }
  }, [user, auth.isAuthenticated]);

  return (
    <>
      <div className="h-full flex flex-row">
        {auth?.isAuthenticated &&
          user &&
          user.trixtaMeta &&
          user.trixtaMeta.roles && (
            <div
              className={`fixed h-full top-0 z-10 flex flex-col content-start bg-nav-grey border-hover-grey border-r-2 w-[12rem] dashboard-nav ${
                windowWidth < 1080 && !mobileSidebar ? "dashboard-exit" : ""
              }`}
            >
              <div className="hover:bg-hover-grey relative hover:text-slate-800">
                <img className="h-[5rem] w-full" src={NavRectangle}></img>
                <img
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14"
                  src={Logo}
                ></img>
              </div>
              <div
                tabIndex={0}
                className="
                flex 
                items-center 
                h-[5rem] 
                text-unfocused-grey 
                hover:bg-hover-grey 
                hover:text-focus-blue
                focus:bg-hover-grey 
                focus:text-focus-blue 
                p-6"
                onClick={() => {
                  setMobileSidebar(false);
                  setTimeout(() => {
                    setHamburger(true);
                  }, 200);
                  navigate("/");
                }}
              >
                <HomeIcon className="h-[60%] w-6 mx-3" />
                Buildings
              </div>
              <div
                tabIndex={1}
                className="
                button 
                flex 
                items-center 
                h-[5rem] 
                text-unfocused-grey 
                hover:bg-hover-grey 
                hover:text-focus-blue
                focus:bg-hover-grey 
                focus:text-focus-blue 
                p-6"
                onMouseEnter={() => setSvgHover(true)}
                onMouseLeave={() => setSvgHover(false)}
                onClick={() => {
                  setMobileSidebar(false);
                  setTimeout(() => {
                    setHamburger(true);
                  }, 200);
                  navigate("/services");
                }}
              >
                {svgHover ? (
                  <ServiceIconFocus className="h-[48%], w-6 mx-3" />
                ) : (
                  <ServiceIcon className="h-[48%], w-6 mx-3" />
                )}
                Services
              </div>
              {user.trixtaMeta.roles.includes("property_manager") && (
                <div
                  className="
                button 
                flex 
                items-center 
                h-[5rem] 
                text-unfocused-grey 
                hover:bg-hover-grey 
                hover:text-focus-blue
                focus:bg-hover-grey 
                focus:text-focus-blue 
                p-6"
                  onClick={() => {
                    setMobileSidebar(false);
                    setTimeout(() => {
                      setHamburger(true);
                    }, 200);
                    navigate("/profile");
                  }}
                >
                  <UserIcon className="h-[48%], w-6 mx-3" />
                  Profile
                </div>
              )}
              <div className="hover:bg-hover-grey mt-auto text-unfocused-grey hover:text-focus-blue p-6">
                <button
                  onClick={() => {
                    logout();
                  }}
                  className="text-sm font-semibold flex"
                >
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="ml-3 mr-3 w-4 h-4"
                  />
                  <p className="align-middle">LOG OUT</p>
                </button>
              </div>
            </div>
          )}
        <div
          className={`${windowWidth < 1080 ? "w-[100%]" : "w-[85%]"} ${
            !auth?.isAuthenticated ? "" : "pt-6 pr-8 px-8 bg-clarity ml-auto"
          }`}
          onClick={() => {
            setMobileSidebar(false);
            setTimeout(() => {
              setHamburger(true);
            }, 200);
          }}
        >
          {children}
        </div>
      </div>
      {windowWidth < 1080 && hamburger ? (
        <Bars3Icon
          className="mr-8 mt-8 w-8 h-8 absolute top-0 right-1"
          onClick={() => {
            setHamburger(false);
            setMobileSidebar(true);
          }}
        />
      ) : (
        <div></div>
      )}
    </>
  );
}
