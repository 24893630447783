import React from "react";

export const Pagenation = ({
  itemsAmount,
  pageNumber,
  setPageNumber,
  maxItems
}: any) => {
  const pageNumbers = Math.ceil(itemsAmount / maxItems);
  const numbers = Array.from(Array(pageNumbers), (x, i) => i + 1);

  return (
    <div className="flex py-6 pl-6">
      <button
        className="mr-6 text-gray-200 hover:text-gray-500"
        onClick={() => {
          setPageNumber(1);
        }}
      >
        {"<<"}
      </button>
      <button
        className="mr-6 text-gray-200 hover:text-gray-500"
        onClick={() => {
          if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1);
          }
        }}
      >
        {"<"}
      </button>

      {numbers.map(number => {
        return (
          <>
            {pageNumber === number ? (
              <button className="mr-4 text-gray-500">{number}</button>
            ) : (
              <button
                className="mr-4 text-gray-300 hover:text-gray-500"
                onClick={() => {
                  setPageNumber(number);
                }}
                key={number}
              >
                {number}
              </button>
            )}
          </>
        );
      })}

      <button
        className="ml-2 text-gray-200 hover:text-gray-500"
        onClick={() => {
          if (pageNumber !== pageNumbers) {
            setPageNumber(pageNumber + 1);
          }
        }}
      >
        {">"}
      </button>
      <button
        className="ml-6 text-gray-200 hover:text-gray-500"
        onClick={() => {
          setPageNumber(pageNumbers);
        }}
      >
        {">>"}
      </button>
    </div>
  );
};
