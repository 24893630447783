export const addUnitFields: any = {
  area: {title: 'Area (sq. ft.)', required: false, disabled: false},
  floor: {title: 'Floor Number', required: false, disabled: false},
  type: {title: 'Type'},
  bathrooms: {title: 'Bathrooms', required: false, disabled: false},
  bedrooms: {title: 'Bedrooms', required: false, disabled: false},
  owner: {title: 'Owner', required: false, disabled: false},
  ownerEmail: {title: 'Owner Email', required: false, disabled: false},
  ownerNumber: {title: 'Owner Number', required: false, disabled: false},
}

export const viewUnitFields: any = {
  owner: {title: 'Owner'},
  ownerEmail: {title: 'Owner Email ', required: false, disabled: false},
  ownerNumber: {title: 'Owner Number', required: false, disabled: false},
  bedrooms: {title: 'Bedrooms'},
  bathrooms: {title: 'Bathrooms'},
  type: {title: 'Type'},
  area: {title: 'Dimensions'}
}

export const addUnitFieldsTextArea: any = {
  description: {title: 'Description', required: false, disabled: false},
  features: {title: 'Features', required: false, disabled: false}
} 

export const unitTenant: any = {
  name: {title: 'Name'},
  email: {title: 'Email'},
  startDate: {title: 'Start Date'},
  endDate: {title: 'End Date'},
  rentStatus: {title: 'Rent Status'}
}

export const unitTenantTemplate: any = {
  name: "",
  email: "",
  startDate: "",
  endDate: "",
  rentStatus: ""
}

export const submitFormTemplateUnit = {
  address: '',
  name: '',
  type: '',
  area: '',
  bathrooms: '',
  images: [],
  tags: [],
  tenants: [],
  commercials: {},
  operations: {},
  bedrooms: '',
  specifications: {
    description: '',
    features: ''
  },
  tenant: {
    tenantName: '',
    tenantEmail: '',
    startDate: '',
    endDate: ''
  },
  floor: '',
  property_id: '',
  unit_id: '',
  owner: '',
  ownerEmail: '',
  price: '',
  rentalTerm: '',
  new_template: true,
  space: 'smarthost.space.trixta.io'
}

export const tenantDetailsTemplate: any = {
  tenantName: {title: 'Tenant Name', required: false, disabled: false},
  tenantEmail: {title: 'Tenant Email', required: false, disabled: false},
  startDate: {title: 'Start Date', required: false, disabled: false},
  endDate: {title: 'End Date', required: false, disabled: false}
}
