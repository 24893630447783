import React, { useEffect } from "react";
import { useTrixta } from "../hooks/useTrixta";
import { useNavigate } from "react-router-dom";

const Logout = () => {

  const { logout } = useTrixta()
  const navigate = useNavigate();

  useEffect(() => {
    logout()
    navigate("/login")
  }, [])

  return (
    <div></div>
  );
};

export default Logout;