import React, { useEffect, useState } from "react";
import { Unit } from "./interfaces";
import { useNavigate } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import useUnitsHook from "../../hooks/useUnits";
import usePropertiesHook from "../../hooks/useProperties";
import { submitFormTemplateUnit } from "../formTemplates/unitTemplates";
import Loading from "../loading";
import { Decode } from "../content/decode";

const UnitList = ({
  property_id,
  loadingUnits,
  currentUnits,
  unitImages,
  building
}: any) => {
  const [initThumbNails, setInitThumbNails] = useState<any>({});
  const [thumbNails, setThumbNails] = useState<any>({});

  const { updateBuildings } = usePropertiesHook();
  const [unitAmt, setUnitAmt] = useState<number>(0);
  const navigate = useNavigate();
  const { addUnit } = useUnitsHook();

  const navToUnitDetails = (unit_number: string) => {
    return navigate(
      `/building-details/${property_id?.toString()}/${unit_number}`
    );
  };

  useEffect(() => {
    if (currentUnits) {
      setUnitAmt(currentUnits.length);
    }
  }, [currentUnits]);

  useEffect(() => {
    if (unitImages) {
      loadThumbnails();
    }
  }, [unitImages, currentUnits]);

  useEffect(() => {
    if (!unitImages || initThumbNails.length !== unitImages.length) {
      return;
    }

    Object.keys(initThumbNails).forEach(thumbnailProp => {
      if (thumbNails[thumbnailProp]) {
        return;
      }
      if (initThumbNails[thumbnailProp] === "empty") {
        setThumbNails((thumbnails: any) => ({
          ...thumbnails,
          [thumbnailProp]: initThumbNails[thumbnailProp]
        }));
      }
      const img = new Image();
      img.src = initThumbNails[thumbnailProp];
      img.onload = () => {
        setThumbNails((thumbnails: any) => ({
          ...thumbnails,
          [thumbnailProp]: initThumbNails[thumbnailProp]
        }));
      };
    });
  }, [initThumbNails, unitImages]);

  const loadThumbnails = async () => {
    if (currentUnits) {
      const allData = await Promise.all(
        Object.keys(unitImages).map(async (prop: any) => {
          if (!unitImages[prop]) {
            return;
          } else if (unitImages[prop].length === 0) {
            return ["empty", prop];
          }
          const mediaName = Decode(unitImages[prop][0][0]);
          if (mediaName !== "thumbnail") {
            return;
          }

          const image = unitImages[prop][0][1];
          if (typeof unitImages[prop][0][1] === "string") {
            return [unitImages[prop][0][1], prop];
          }
          const res = Decode(image);
          // const response = await fetch(res);
          // const base64Image = await response.text();
          return [res, prop];
        })
      );

      const newThumbnailObj: any = {};
      allData.forEach((prop: any) => {
        newThumbnailObj[prop[1]] = prop[0];
      });

      setInitThumbNails(newThumbnailObj);
    }
  };

  const handleAddUnit = () => {
    const tempProperty = {
      ...submitFormTemplateUnit,
      name: "No Name",
      property_id: property_id,
      unit_id: Date.now().toString()
    };
    addUnit(tempProperty);
    updateBuildings(building, "edit");
    navigate(
      `/building-details/${property_id.toString()}/new_${tempProperty.unit_id}`
    );
  };

  return (
    <div className="w-full py-10">
      <div className="flex pb-4">
        <div className="pr-2 text-2xl font-bold border-solid border-slate-200 text-focus-blue">
          Units ({unitAmt})
        </div>
        <button onClick={handleAddUnit}>
          <PlusCircleIcon className="w-8 h-8 text-edit-grey mt-[1px]" />
        </button>
      </div>
      {!loadingUnits && currentUnits ? (
        <>
          <div className="mb-8">
            {currentUnits.map((unit: Unit, index: number) => {
              const { name, bedrooms, bathrooms, owner, type, unit_id } = unit;
              return (
                <div key={unit_id} className="w-full mb-8">
                  <h3 className="text-lg text-focus-blue font-semibold min-h-[2rem] mb-1">
                    {name ? name : "No Name"}
                  </h3>
                  <div
                    className="
                        rounded-xl 
                        text-clarity
                        bg-field-grey
                        mb-8 
                        flex flex-row 
                        w-full h-52"
                  >
                    <div className="w-[50%] h-full bg-hover-grey">
                      {thumbNails[unit.unit_id] === "empty" ? (
                        <div className="w-full h-full flex items-center justify-center"></div>
                      ) : thumbNails[unit.unit_id] ? (
                        <img
                          className="w-full h-full rounded-l-xl object-cover"
                          src={thumbNails[unit.unit_id]}
                        />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full flex flex-col align-center flex-wrap w-[50%]">
                      <div className="w-[90%] p-10 pl-14 space-y-4">
                        <div className="break-words flex text-sm">
                          <p className="font-semibold w-[35%]">Owner:</p>
                          <p className="w-[65%]">{owner ? owner : "N/A"}</p>
                        </div>
                        <div className="break-words flex text-sm">
                          <p className="font-semibold w-[35%]">Bedrooms:</p>
                          <p className="w-[65%]">
                            {bedrooms ? bedrooms : "N/A"}
                          </p>
                        </div>
                        <div className="break-words flex text-sm">
                          <p className="font-semibold w-[35%]">Bathrooms:</p>
                          <p className="w-[65%]">
                            {bathrooms ? bathrooms : "N/A"}
                          </p>
                        </div>
                        <div className="break-words flex text-sm">
                          <p className="font-semibold w-[35%]">Type:</p>
                          <p className="w-[65%]">{type ? type : "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto w-24">
                    <button
                      className="py-2 px-4 bg-focus-blue text-clarity rounded-full text-xs text-center"
                      onClick={() => navToUnitDetails(unit_id)}
                    >
                      VIEW UNIT
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default UnitList;
