export const profileTemplate: any = {
  companyName: {title: 'Company Name', required: false, disabled: false},
  address: {title: 'Address', required: false, disabled: false},
  email: {title: 'Email', required: false, disabled: false}
}

export const contactPersonTemplate: any = {
  contactName: {title: 'Name', required: false, disabled: false},
  contactSurname: {title: 'Surname', required: false, disabled: false},
  contactEmail: {title: 'Email', required: false, disabled: false},
  contactNumber: {title: 'Contact Number', required: false, disabled: false},
  designation: {title: 'Designation', required: false, disabled: false},
}