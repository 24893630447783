import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import configureTrixtaStore from "./store";
import AuthWrapper from "./components/auth/authWrapper";
import { setupCustomFormTemplate } from "./components/rjsf";
//import Buildings from "./pages/buildings";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/login";
import Logout from "./pages/logout";
import VerifyPage from "./pages/verify";
import RolesPage from "./pages/roles";
import Services from "./pages/services";
import ServicesEvent from "./pages/ServicesEvent"
import Profile from "./pages/profile";
import BuildingDetails from "./pages/buildingDetails";
import UnitDetails from "./pages/unitDetails";
import Layout from "./components/layout";
import "./styles/main.css";

const store = configureTrixtaStore();

function App() {
  useEffect(() => {
    setupCustomFormTemplate();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthWrapper>
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/verify" element={<VerifyPage />} />
              <Route path="/roles" element={<RolesPage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/event" element={<ServicesEvent />}/>
              <Route path="/profile" element={<Profile />} />
              <Route path="/building-details/:slug" element={<BuildingDetails />} />
              <Route path="/building-details/:slug/:slug" element={<UnitDetails />} />
            </Routes>
          </Layout>
        </AuthWrapper>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
